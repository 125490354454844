export default function PageTitle({ html }) {
    const titleRegExp = /<title>(.*)<\/title>/im;
    this.update = ({ html }) => {
        const expResult = titleRegExp.exec(html.rawHtml);
        const title = expResult && expResult.length ? expResult[1] : '';
        if (title) {
            document.title = title;
        }
    };
}

import {
    addEvent,
    addLiveEvent,
} from 'buikit/src/assets/js/utils/eventHandling';
import {
    getFormData,
    resetFieldValue,
} from 'buikit/src/assets/js/utils/formHandling';
import queryString from 'query-string';

export default function Contactsearch({ container, history }) {
    const self = this;
    this.toFinalize = [];
    this.initialize = () => {
        this.eventListeners = [];
        this.facet = container.querySelector('.contactsearch__facet');
        this.form = this.facet.querySelector('.contactsearch__form');
        this.results = container.querySelector('.contactsearch__results');
        this.tagBar = this.results.querySelector('.contactsearch__tag-bar');

        this.delayTimer = null;

        this.formInputs = this.form.querySelectorAll('[name]');
        this.formInputs.forEach((element) => {
            if (element.type === 'text') {
                this.eventListeners.push(
                    addEvent(element, 'input', (e) =>
                        this.handleTextInputChange(e)
                    )
                );
            } else {
                this.eventListeners.push(
                    addEvent(element, 'change', this.pushData)
                );
            }
        });

        this.update({ container });

        this.eventListeners.push(
            addLiveEvent('.contactsearch__tag', 'click', handleTag, container)
        );

        this.eventListeners.push(
            addLiveEvent(
                '.contactsearch__remove-tag',
                'click',
                function () {
                    self.formInputs.forEach((element) =>
                        resetFieldValue(element, true)
                    );
                    self.formInputs[0].dispatchEvent(new Event('input'));
                },
                container
            )
        );
    };

    this.handleTextInputChange = (e) => {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.pushData();
        }, 1000);
    };

    this.pushData = () => {
        const data = getFormData(this.form);
        for (let key in data) {
            if (Array.isArray(data[key])) {
                data[key] = data[key].sort().join(',');
            }
        }
        history.push({
            pathname: this.form.getAttribute('action'),
            search: queryString.stringify(data),
        });
    };
    const handleTag = function (e) {
        const key = this.getAttribute('data-filter-key');
        const filterElement = self.facet.querySelector('[name^="' + key + '"]');
        if (filterElement.name.endsWith('[]')) {
            const groupElements = self.facet.querySelectorAll(
                '[name^="' + key + '"]'
            );
            groupElements.forEach((element) => {
                resetFieldValue(element, true);
            });
        } else {
            resetFieldValue(filterElement, true);
        }

        //console.log(filterElement);
        if (filterElement.tagName == 'INPUT') {
            filterElement.dispatchEvent(new Event('input'));
        } else {
            filterElement.dispatchEvent(new Event('change'));
        }
    };
    this.beforeUpdate = () => {};
    this.update = ({ container }) => {
        const newResults = container.querySelector('.contactsearch__results');
        this.results.replaceWith(newResults);
        this.results = newResults;
    };
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach((event) => event.remove());
        }
    };
    this.initialize();
}

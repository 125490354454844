import appConfig from '../../assets/js/config';
import {tns} from 'tiny-slider/src/tiny-slider';
import Bui from '../../assets/js/Bui';

export default function Contentteaser({container, history, config}) {
    this.toFinalize = [];
    this.initialize = () => {
        const self = this;
        const autoplay = {};
        if (!!config && !!config.autoplay && config.autoplay > 0) {
            autoplay.autoplayTimeout = config.autoplay;
            autoplay.autoplay = true;
        }
        if (appConfig) {
            this.wrapper = container.querySelector('.contentslider__wrapper');
            this.controls = container.querySelector('.contentslider__controls');

            if (this.wrapper) {
                this.swiper = new tns({
                    container: this.wrapper,
                    items: 1,
                    slideBy: 'page',
                    mouseDrag: true,
                    preventActionWhenRunning: true,
                    controls: true,
                    controlsContainer: this.controls,
                    controlsPosition: 'bottom',
                    nav: !!config && !!config.showNav || false,
                    navPosition: 'bottom',
                    lazyload: true,
                    ...autoplay,
                });
            }
        }

        this.bui = new Bui({
            container: container,
        });
    };
    this.finalize = () => {
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.bui && typeof this.bui.finalize === 'function') {
            this.bui.finalize();
        }
    };
    this.initialize();
}

import { CALL_HTML } from '../middleware/htmlRequest';
import queryString from 'query-string';

export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const CLEAR_BOOKMARK = 'CLEAR_BOOKMARK';

export const addBookmark = (id) => ({
    type: ADD_BOOKMARK,
    id,
});

export const deleteBookmark = (id) => ({
    type: DELETE_BOOKMARK,
    id,
});

export const clearBookmark = () => ({
    type: CLEAR_BOOKMARK,
});

export const ADD_SHOPPING_CART_ITEM = 'ADD_SHOPPING_CART_ITEM';
export const DELETE_SHOPPING_CART_ITEM = 'DELETE_SHOPPING_CART_ITEM';
export const SET_SHOPPING_CART_ITEMS = 'SET_SHOPPING_CART_ITEMS';
export const CLEAR_SHOPPING_CART_ITEMS = 'CLEAR_SHOPPING_CART_ITEMS';

export const addShoppingCartItem = (id, count) => ({
    type: ADD_SHOPPING_CART_ITEM,
    id,
    count,
});

export const deleteShoppingCartItem = (id) => ({
    type: DELETE_SHOPPING_CART_ITEM,
    id,
});

export const setShoppingCartItem = (items) => ({
    type: SET_SHOPPING_CART_ITEMS,
    items,
});

export const clearShoppingCartItems = () => ({
    type: CLEAR_SHOPPING_CART_ITEMS,
});

export const HTML_REQUEST = 'HTML_REQUEST';
export const HTML_SUCCESS = 'HTML_SUCCESS';
export const HTML_FAILURE = 'HTML_FAILURE';

// Fetches a single page from API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchUrl = (key, config = {}, location) => ({
    key,
    config,
    location,
    [CALL_HTML]: {
        types: [HTML_REQUEST, HTML_SUCCESS, HTML_FAILURE],
        key,
        config,
        location,
    },
});

// Fetches a single page from the API.
// Relies on Redux Thunk middleware.
export const pushUrl =
    (config = {}) =>
    (dispatch) => {
        // TODO Cache switch — will it cause problems? Maybe use it on history pop
        // const page = getState().entities.pages[`${url}`];
        // if (page) {
        //     return null;
        // }
        const search =
            config.params && Object.keys(config.params).length
                ? `?${queryString.stringify(config.params)}`
                : '';

        const key = `${config.url || window.location.pathname}${search}`;
        const location = {
            pathname: config.url || '',
            search,
        };
        return dispatch(fetchUrl(key, config, location));
    };

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

// Resets the currently visible error message.
export const resetErrorMessage = () => ({
    type: RESET_ERROR_MESSAGE,
});

// Pushes the inital markup
export const pushMarkup =
    (config = {}) =>
    (dispatch) => {
        const search =
            config.params && Object.keys(config.params).length
                ? `?${queryString.stringify(config.params)}`
                : '';

        const key = `${config.url || window.location.pathname}${search}`;
        const location = {
            pathname: config.url || '',
            search,
        };
        return dispatch({
            type: HTML_SUCCESS,
            response: {
                data: document.documentElement.outerHTML,
            },
            location,
            key,
        });
    };

import config from "../config";

const infoWindowContent = function (loc) {
    return `<div class="googlemap__infowindow text-body">
                <h3 class="googlemap__infowindow__headline hl mod--caps margin-top-1">
                    Standort ${loc.name}
                </h3>
                ${loc.street && loc.city? `<p class="googlemap__infowindow__address">
                    ${loc.street}
                    <br> ${loc.city}
                    <br>
                ${loc.phone? 
                    `<a href="tel:${loc.phone}" class="googlemap__infowindow__phone">
                        ${loc.phone}
                    </a>`
                    : ``}
                </p>`: ``
                }
                ${loc.link? `<p class="googlemap__infowindow__link">
                    <a href="${loc.link}" class="button">Standortseite aufrufen</a>
                    </p>`: ``
                }
            </div>`;
};

window.initializeGoogleMap = () => {

    function setMarkers(map, locations, infoWindow, zoomlevel, bounds) {
        if (locations) {
            locations.forEach((loc) => {
                let marker = new google.maps.Marker({
                    position: loc.position,
                    map: map,
                    icon: {
                        url:
                            "data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.828 0c6.024 0 10.828 4.983 10.828 11.081 0 4.983-2.826 9.717-5.287 12.952-2.578 3.36-5.166 5.61-5.166 5.728-.131.131-.248.131-.375.131-.126 0-.248 0-.375-.131 0-.122-2.578-2.372-5.165-5.728C6.826 20.793 4 16.064 4 11.08 4 4.983 8.795 0 14.828 0zm-.075 5.747a5.085 5.085 0 00-5.081 5.081 5.085 5.085 0 005.081 5.081 5.085 5.085 0 005.081-5.08 5.085 5.085 0 00-5.08-5.082z' fill='%2356BFCA' fill-rule='nonzero'/%3E%3C/svg%3E",
                        size: new google.maps.Size(30, 30),
                    },
                });

                google.maps.event.addListener(marker, 'click', () => {
                    infoWindow.close();
                    infoWindow.setContent(infoWindowContent(loc));
                    infoWindow.open(map, marker);
                    map.panTo(loc.position);
                    map.setZoom(15);
                });

                if (locations.length > 1) {
                    bounds.extend(loc.position);
                } else {
                    infoWindow.close();
                    infoWindow.setContent(infoWindowContent(loc));
                    infoWindow.open(map, marker);
                    map.panTo(loc.position);
                    map.setZoom(zoomlevel);
                }

                google.maps.event.addListener(infoWindow, 'closeclick', () => {
                    if (locations.length > 1) {
                        map.fitBounds(bounds);
                    } else {
                        map.setZoom(zoomlevel);
                    }
                });
            });

            if (locations.length > 1) {
                map.fitBounds(bounds);
            }
        }
    }

    function initMap(elementsID) {
        const container = document.getElementById(elementsID);
        const mapContainer = container.querySelector('[data-map-container]');
        const mapValues = container.querySelector('[data-map-values]');

        const locationsRAW = mapValues.dataset.mapLocations.split(';');
        locationsRAW.pop();

        const locations = locationsRAW.map((loc) => {
                return parseLocation(JSON.parse(loc))
            }
        );

        const zoomlevel = parseInt(mapValues.dataset.mapZoomlevel);
        var myOptions = {
            zoom: zoomlevel
        };

        const infoWindow = new google.maps.InfoWindow();
        const map = new google.maps.Map(mapContainer, myOptions);
        const bounds = new google.maps.LatLngBounds();

        setMarkers(map, locations, infoWindow, zoomlevel, bounds);
    }

    document
        .querySelectorAll(".googlemap")
        .forEach((component) => {
            // try, because JSON.parse can crash if somebody put in unparseable code
            try {
                const componentConfig = JSON.parse(
                    component.getAttribute(config.componentIdentifier)
                );
                initMap(componentConfig.randomClass)
            } finally {
            }
        });
};


const parseLocation = (location) => {
    return {
        name: location.name,
        street: location.street || null,
        city: location.city || null,
        link: location.link || null,
        phone: location.phone || null,
        position: {
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lng),
        },
    };
};

export default function GoogleMaps({html}) {

    this.inititalized = false;

    this.initialize = ({html}) => {
    };


    this.update = ({html}) => {
        const mapsOnPage = document.querySelectorAll(".googlemap");
        const weHaveMapsToInit = mapsOnPage && mapsOnPage.length > 0;

        // THE VERY FIRST TIME AFTER HARD LOADED BERESA WE SIMULATE A PAGECHANGE
        if (weHaveMapsToInit && !this.inititalized) {
            this.onPageChange(html);
        }
    };

    // TODO: Better to use Iframes or a pool of gmap references as described here:
    // https://stackoverflow.com/questions/10485582/what-is-the-proper-way-to-destroy-a-map-instance
    this.onPageChange = ({html}) => {
        this.inititalized = true;

        const existingScriptContainers = document.querySelectorAll(".googleMapsLoadingScript");

        if (!!existingScriptContainers && existingScriptContainers.length > 0) {

            for (let i = 0; i < existingScriptContainers.length; i++) {
                existingScriptContainers[i].remove();
            }

            // Removes google maps multiple instance scripts
            let scripts = document.getElementsByTagName("script");
            for (let i = 0; i < scripts.length; i++) {
                let script = scripts[i];
                if (script.src.includes('maps.googleapis')) {
                    script.parentNode.removeChild(script);
                }
            }
        }

        const mapsOnPage = document.querySelectorAll(".googlemap");

        // BULD MAPS NEW AND ALSO THE MAPS SRC SCRIPT. OTHERWISE IT WILL NOT WORK!!!!
        if (mapsOnPage && mapsOnPage.length > 0) {
            const scriptClass = "googleMapsLoadingScript";

            let gMapsSrc = document.createElement('script');
            gMapsSrc.type = 'text/javascript';
            gMapsSrc.async = false;
            gMapsSrc.defer = true;
            gMapsSrc.src = "//maps.googleapis.com/maps/api/js?key=AIzaSyBfzBxH5PoqqbDGni5zAYjFy8UdQebAz9o&callback=" + "initializeGoogleMap";
            gMapsSrc.classList.add(scriptClass);

            let s = document.getElementsByTagName('head')[0];
            s.appendChild(gMapsSrc);
        }
    };
}

import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";
import {addEvent} from "buikit/src/assets/js/utils/eventHandling";
import Bui from "../../assets/js/Bui";
import {getFormData} from "buikit/src/assets/js/utils/formHandling";

export default function CockpitCars({container, history, config}) {
    this.toFinalize = [];
    const parser = new DOMParser();
    this.initialize = () => {
        this.container = container;
        this.bui = new Bui({container});
        this.eventListeners = [];
        this.initForm();
    };
    this.initForm = () => {
        this.form = container.querySelector("form");

        this.formButtons = this.container.querySelectorAll(
            '.cockpit-cars__data-form-button'
        );

        if (this.formButtons) {
            this.formButtons.forEach(element => {
                this.eventListeners.push(
                    addEvent(element, 'click', (evt) => {
                        evt.preventDefault();
                        this.performRestCall(evt);
                    })
                );
            });
        }
    }
    this.performRestCall = (evt) => {
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
        let actionUrl = this.form.getAttribute('action');
        actionUrl = removeURLParameter(actionUrl, "action");
        actionUrl = removeURLParameter(actionUrl, "fin");
        actionUrl = removeURLParameter(actionUrl, "checkSum");
        actionUrl = removeURLParameter(actionUrl, "id");
        actionUrl = removeURLParameter(actionUrl, "last_general_inspection_day");
        actionUrl = removeURLParameter(actionUrl, "last_general_inspection_month");
        actionUrl = removeURLParameter(actionUrl, "last_general_inspection_year");
        actionUrl = removeURLParameter(actionUrl, "mileage");
        let params = getFormData(this.container.querySelector("form")) || {};
        params.action = evt.target.getAttribute("value");
        if (params.action === "delete") {
            params.id = evt.target.getAttribute("data-car-id");
        }
        fetchUrl({
            params,
            url: actionUrl,
            method: this.form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    const dom = parser.parseFromString(response.data, 'text/html');
                    const newContainer = dom.querySelector('[data-component=\'{"name": "CockpitCars"}\']');
                    this.container.innerHTML = newContainer.innerHTML;

                    this.bui = new Bui({container});
                    this.initForm();
                }
            );
    };
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(event => event.remove());
        }
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
    };

    function removeURLParameter(url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    this.initialize();
}

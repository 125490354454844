import {addEvent} from "buikit/src/assets/js/utils/eventHandling";
import fetchUrl from 'buikit/src/assets/js/utils/fetchUrl';
import Bui from "../../assets/js/Bui";
import {addShoppingCartItem, deleteShoppingCartItem} from "../../assets/js/actions";
import Carslider from "../carslider";
import transform from "buikit/src/assets/js/utils/transform";

export default function ShoppingCart({container, history, config, store}) {
    const self = this;
    const parser = new DOMParser();
    this.toFinalize = [];
    this.initialize = () => {
        this.container = container;
        this.bui = new Bui({container});
        this.eventListeners = [];

        this.formInputs = container.querySelectorAll('[name]');
        this.formContainer = container.querySelectorAll('.shoppingcart-list__form');
        this.formInputs.forEach(element => {
            this.eventListeners.push(
                addEvent(element, 'change', (evt) => {
                    this.pushData(evt);
                })
            );
        });

        this.formContainer.forEach(element => {
            this.eventListeners.push(
                addEvent(element, 'submit', (evt) => {
                    this.pushDataFromFormContainer(evt);
                })
            );
        });

        // Initialize all carsliders
        this.carsliders = [...container.querySelectorAll('.carslider')].map(
            slider => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: true,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'horizontal',
                    threeSixty: has360 ? true : false,
                });
            }
        );

        self.swipeCards = [];
        self.timeouts = [];
        container.querySelectorAll('.shoppingcart-result__card').forEach(card => {
            const swipeCard = {
                remove(diff = 0) {
                    card.classList.add('move');
                    transform(
                        card,
                        `translateX(${diff < 0 ? diff - 200 : diff + 200}px)`
                    );
                    card.style.opacity = 0.001;
                    self.timeouts.push(
                        setTimeout(function () {
                            card.remove();
                        }, 360)
                    );
                },
            };
            this.eventListeners.push(
                addEvent(
                    card.querySelector('.s-car-card__close'),
                    'click',
                    () => {
                        const form = card.querySelector('[data-shoppinglist-item]');
                        this.performeRestCall(form, 0);
                        swipeCard.remove();
                    }
                )
            );
        });

    };

    this.pushData = (evt) => {
        const form = evt.target.parentElement.parentElement;

        this.performeRestCall(form, evt.target.value);
    };

    this.pushDataFromFormContainer = (evt) => {
        evt.preventDefault()
        const form = evt.target;
        const quantity = form.querySelector('[name]').value;

        this.performeRestCall(form, quantity);
    }

    this.performeRestCall = (form, quantity) => {
        const namespace = form.dataset.shoppinglistNamespace;
        const params = {
            [`${namespace}:productId`]: form.dataset.shoppinglistItem,
            [`${namespace}:sku`]: form.dataset.shoppinglistVariant,
            [`${namespace}:quantity`]: quantity,
        };
        fetchUrl({
            params,
            url: form.action,
            method: form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    if (self.bui) self.bui.finalize();
                    if (self.tween) self.tween.kill();
                    const dom = parser.parseFromString(response.data, 'text/html');

                    // Update title
                    const newTitleContainer = dom.querySelector('.shoppingcart-result__title-container');
                    self.newTitleContainer = self.container.querySelector('.shoppingcart-result__title-container');
                    self.newTitleContainer.innerHTML = newTitleContainer.innerHTML;

                    // Update prices in car- and part-card
                    const newPriceContainers = dom.querySelectorAll('.shoppingcart-list__price-container');
                    self.priceContainers = self.container.querySelectorAll('.shoppingcart-list__price-container');
                    self.priceContainers.forEach(oldContainer => {
                        const productId = oldContainer.dataset.shoppinglistProductVariantSku;
                        newPriceContainers.forEach(newContainer => {
                            const newContainerProductId = newContainer.dataset.shoppinglistProductVariantSku;
                            if (productId === newContainerProductId) {
                                oldContainer.innerHTML = newContainer.innerHTML;
                            }
                        });
                    });

                    // Update price and quantity in shopping list summary
                    const priceAndCounterContainers = ['.shoppingcart-summary__container', '.shoppingcart-result__price-container', '.shoppingcart-result__count-container'];
                    priceAndCounterContainers.forEach(entry => {
                        const newTotalContainer = dom.querySelector(entry);
                        self[entry] = self.container.querySelector(entry);
                        if (self[entry] !== undefined && self[entry] != null) {
                            if (newTotalContainer !== undefined && newTotalContainer != null) {
                                self[entry].innerHTML = newTotalContainer.innerHTML;
                            } else {
                                self[entry].remove();
                            }
                        }
                    });

                    // Update line item quantity in store for header bubble
                    let allNewVariantIds = [];
                    const forms = dom.querySelectorAll(".shoppingcart-list__form");
                    forms.forEach(form => {
                        const variantId = form.getAttribute("data-shoppinglist-variant");
                        allNewVariantIds.push(variantId);
                        const inputField = form.querySelector("input");
                        if (inputField && variantId) {
                            const count = inputField.getAttribute("value");
                            store.dispatch(addShoppingCartItem(variantId, parseInt(count)));
                        }
                    });

                    // Remove deleted line items from store
                    const {shoppingCartElements} = store.getState();
                    let variantIdsToRemove = [];
                    for (let property in shoppingCartElements) {
                        if (shoppingCartElements.hasOwnProperty(property)) {
                            if (!allNewVariantIds.includes(property)) {
                                variantIdsToRemove.push(property);
                            }
                        }
                    }
                    variantIdsToRemove.forEach(id => {
                        store.dispatch(deleteShoppingCartItem(id));
                    });

                    if (!dom.querySelector('.shoppingcart-result__line-items-container')) {
                        const lineItemContainer = self.container.querySelector('.shoppingcart-result__line-items-container');
                        if (lineItemContainer) {
                            lineItemContainer.remove();
                        }
                    }

                    const newMoreThanOneCarError = dom.querySelector("#moreThanOneCarError");
                    const moreThanOneCarError = self.container.querySelector('#moreThanOneCarError');
                    if (!newMoreThanOneCarError) {
                        if (moreThanOneCarError) {
                            moreThanOneCarError.classList.add("visuallyhidden");
                        }
                    }

                    this.bui = new Bui({container});
                }
            );
    };
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(event => event.remove());
        }
        if (this.carsliders) {
            this.carsliders.forEach(slider => slider.finalize());
        }
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (self.bui) self.bui.finalize();
        if (self.tween) self.tween.kill();
    };
    this.initialize();
}

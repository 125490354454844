import Leasing from './leasing-subcomponent';

export default function LeasingComponent(props) {
    this.initialize = () => {
        this.leasingCalculator = new Leasing(props);
    };
    this.finalize = () => {
        if (this.leasingCalculator) {
            this.leasingCalculator.finalize();
        }
    };
    this.initialize();
}

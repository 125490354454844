import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";
import {addEvent} from "buikit/src/assets/js/utils/eventHandling";
import Bui from "../../assets/js/Bui";
import {getFormData} from "buikit/src/assets/js/utils/formHandling";
import {createPopper} from '@popperjs/core';

export default function CockpitAlerts({container, history, config}) {
    this.toFinalize = [];
    const parser = new DOMParser();
    this.initialize = () => {
        this.container = container;
        this.bui = new Bui({container});
        this.eventListeners = [];
        this.initForm();

    };

    this.openModal = (e) => {
        if (!e.target.disabled) {
            const copyOfInfotext = e.target.getAttribute("data-modal-description");
            const textWrapper = container.querySelector('.deletemodal__textwrapper');
            if (!!copyOfInfotext && !!textWrapper) {
                textWrapper.innerHTML = copyOfInfotext;
            }
            const alertName = e.target.getAttribute("data-alert-name");
            const nameWrapper = container.querySelector('.deletemodal__namewrapper');
            if (!!alertName && !!nameWrapper) {
                nameWrapper.innerHTML = alertName;
            }
            const alertId = e.target.getAttribute("data-alert-id");
            //const okModalButton = container.querySelector('.deletemodal__ok');
            this.okModalButton.setAttribute("data-alert-id", alertId);
            this.modal.classList.add("visible");
        }
    };


    this.closeModal = () => {
        this.modal.classList.remove("visible");
    };

    this.initForm = () => {
        this.form = container.querySelector("form");
        this.formButtons = this.container.querySelectorAll(
            '.cockpit-alerts__data-form-button'
        );
        this.formCheckboxes = this.container.querySelectorAll(
            '.cockpit-alerts__data-form-checkbox input[name="exactmatch"]'
        );
        if (this.formButtons) {
            this.formButtons.forEach(element => {
                this.eventListeners.push(
                    addEvent(element, 'click', (evt) => {
                        evt.preventDefault();
                        this.handleButtonClick(evt);
                    })
                );
            });
        }

        if (this.formCheckboxes) {
            this.formCheckboxes.forEach(element => {
                this.eventListeners.push(
                    addEvent(element, 'click', (evt) => {
                        evt.preventDefault();
                        this.handleExactMatchClick(evt);
                    })
                );
            });
        }

        this.openModalDelete = document.querySelectorAll('#deleteButton');
        this.modal = this.container.querySelector('.deletemodal');
        this.closeModalIcon = document.querySelector('.deletemodal__close');
        this.okModalButton = document.querySelector('.deletemodal__ok');
        this.closeModalBackdrop = document.querySelector('.deletemodal__backdrop');

        if (this.openModalDelete) {
            if (this.openModalDelete.length > 0) {
                this.openModalDelete.forEach(button => {
                    this.eventListeners.push(
                        addEvent(button, "click", this.openModal)
                    );
                });
            }
        }
        if (this.okModalButton) {
            this.eventListeners.push(
                addEvent(this.okModalButton, "click", this.closeModal)
            );
        }
        if (this.closeModalIcon) {
            this.eventListeners.push(
                addEvent(this.closeModalIcon, "click", this.closeModal)
            );
        }
        if (this.closeModalBackdrop) {
            this.eventListeners.push(
                addEvent(this.closeModalBackdrop, "click", this.closeModal)
            );
        }


        // Init tooltip
        const infoIcons = container.querySelectorAll('.alerts__list__item__info');
        const tooltipInfos = container.querySelectorAll('.alerts__list__item__tooltip-item-container');
        infoIcons.forEach((icon, index) => {
            createPopper(icon, tooltipInfos[index]);
        })

    }

    this.handleExactMatchClick = (evt) => {
        let params = {};
        params.action = evt.target.getAttribute("name");
        params.value = !(evt.target.getAttribute("value") === 'true');
        params.id = evt.target.getAttribute("data-alert-id");
        this.performRestCall(params);
    }

    this.handleButtonClick = (evt) => {
        let params = getFormData(this.container.querySelector("form")) || {};
        params.action = evt.target.getAttribute("value");
        if (params.action === "delete" || params.action === "activate" || params.action === "deactivate") {
            params.id = evt.target.getAttribute("data-alert-id");
        }
        this.performRestCall(params);
    }

    this.performRestCall = (params) => {
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
        let actionUrl = this.form.getAttribute("action");
        actionUrl = removeURLParameter(actionUrl, "action");
        actionUrl = removeURLParameter(actionUrl, "id");
        actionUrl = removeURLParameter(actionUrl, "value");
        fetchUrl({
            params,
            url: actionUrl,
            method: this.form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    const dom = parser.parseFromString(response.data, 'text/html');
                    const newContainer = dom.querySelector('[data-component=\'{"name": "CockpitAlerts"}\']');
                    this.container.innerHTML = newContainer.innerHTML;
                    this.bui = new Bui({container});
                    this.initForm();
                }
            );
    };
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(event => event.remove());
        }
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
    };

    function removeURLParameter(url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    this.initialize();
}

import {
    addEvent,
    addLiveEvent,
} from 'buikit/src/assets/js/utils/eventHandling';
import {
    getFormData,
    resetFieldValue,
} from 'buikit/src/assets/js/utils/formHandling';
import queryString from 'query-string';

export default function JobDetail({ container, history }) {
    const self = this;
    this.toFinalize = [];
    this.eventListeners = [];

    this.initialize = () => {
        const printBtn = container.querySelector('.jobitem-print-btn');

        this.eventListeners.push(
            addEvent(printBtn, 'click', function () {
                window.print();
            })
        );
    };

    this.beforeUpdate = () => {};

    this.update = ({ container }) => {};

    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach((listener) => {
                listener.remove();
            });
        }
    };

    this.initialize();
}

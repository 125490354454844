import ThreeSixty from '@mladenilic/threesixty.js/src/threesixty.js';
import 'pannellum';
import 'pannellum/build/pannellum.css';

export default function Threesixtymodal(element, options) {
    this.initialize = () => {
        const self = this;
        this.state = {
            activeContent: '360',
        };
        this.threeSixtyViewer;
        this.panoramaViewer;
        this.modal = element;
        this.modalWrapper = this.modal.querySelector(
            '.threesixtymodal__wrapper'
        );
        this.modalHeadline = this.modal.querySelector(
            '.threesixtymodal__headline'
        );
        this.modalContent = this.modal.querySelector(
            '.threesixtymodal__content'
        );

        this.button360 = element.querySelector('.threesixtymodal__button--360');
        this.buttonPanorama = element.querySelector(
            '.threesixtymodal__button--panorama'
        );
        this.buttonClose = element.querySelector('.threesixtymodal__close');
        this.content360 = element.querySelector('.threesixtymodal__360');
        this.contentPanorama = element.querySelector(
            '.threesixtymodal__panorama'
        );
        this.threeSixtyAssets = this.modal.dataset.outside.split(',');
        this.threeSixtyAssets.pop();
        this.panoramaAssets = this.modal.dataset.inside.split(',');
        this.panoramaAssets.forEach((img) => {
            if (img.includes('zoom.jpg')) {
                this.smallPanorama = img + '?' + Math.floor(Date.now() / 1000);
            } else {
                this.bigPanorama = img + '?' + Math.floor(Date.now() / 1000);
            }
        });

        /**
         * EventListener
         */
        this.button360.addEventListener('click', (e) => {
            this.setState({ activeContent: '360' });
        });
        this.buttonPanorama.addEventListener('click', (e) => {
            this.setState({
                activeContent: 'Panorama',
            });
        });
        this.buttonClose.addEventListener('click', this.closeModal);

        this.modal.addEventListener('click', (e) => {
            if (e.target.classList.contains('threesixtymodal'))
                return this.closeModal();
        });

        this.showModal();
        this.render();
    };

    this.setState = (newState) => {
        this.state = {
            ...this.state,
            ...newState,
        };
        this.render();
    };

    this.getCurrentModalSizes = () => {
        this.modalContentWidth = this.modalContent.clientWidth;
        this.modalHeadlineHeight = this.modalHeadline.clientHeight;
        this.modalContentHeight = this.modalContent.clientHeight;
        this.maxThreeSixtyHeight = 787 - 20;
        this.threeSixtyHeight = (this.modalContentWidth / 16) * 9;

        if (this.threeSixtyHeight > this.maxThreeSixtyHeight) {
            this.threeSixtyHeight = this.maxThreeSixtyHeight;
        }
    };

    this.getBrowserWidth = () => {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    };

    this.setModalHeight = () => {
        this.modalWrapperHeight = this.threeSixtyHeight;
        this.modalWrapper.style.height = `${this.modalWrapperHeight}px`;
    };

    this.showModal = (e) => {
        this.modal.classList.add('visible');
    };

    this.closeModal = (e) => {
        this.modal.classList.remove('visible');
        if (this.threeSixtyViewer) this.threeSixtyViewer.destroy();
        if (this.panoramaViewer) this.panoramaViewer.destroy();
    };

    this.initializeThreeSixtyViewer = () => {
        if (this.threeSixtyAssets) {
            const threeSixtyViewerContainer = element.querySelector(
                '#threesixtymodal__360__viewer'
            );

            this.threeSixtyViewer = new ThreeSixty(threeSixtyViewerContainer, {
                image: this.threeSixtyAssets,
                width: this.modalContentWidth,
                height: this.threeSixtyHeight,
                swipeable: true,
                draggable: true,
            });

            this.threeSixtyViewer.goto(20);
        }
    };

    this.initializePanoramaViewer = () => {
        const panoramaViewerContainer = this.modal.querySelector(
            '#threesixtymodal__panorama__viewer'
        );

        panoramaViewerContainer.style.height = `${this.threeSixtyHeight}px`;

        this.panoramaViewer = pannellum.viewer(
            'threesixtymodal__panorama__viewer',
            {
                type: 'equirectangular',
                autoLoad: true,
                panorama:
                    this.getBrowserWidth() > 1024
                        ? this.bigPanorama
                        : this.smallPanorama,
                showControls: false,
                strings: {
                    loadingLabel: 'Wird geladen...',
                },
            }
        );
    };

    this.render = (rerender = false) => {
        this.getCurrentModalSizes();
        this.setModalHeight();

        if (this.state.activeContent === '360') {
            this.content360.classList.add('visible');
            this.contentPanorama.classList.remove('visible');
            this.button360.classList.remove('mod--shy');
            this.buttonPanorama.classList.add('mod--shy');
            if (!this.threeSixtyViewer) {
                this.initializeThreeSixtyViewer();
            }
            if (rerender === true && this.threeSixtyViewer) {
                this.threeSixtyViewer.destroy();
                this.initializeThreeSixtyViewer();
            }
        } else if (this.state.activeContent === 'Panorama') {
            this.content360.classList.remove('visible');
            this.contentPanorama.classList.add('visible');
            this.button360.classList.add('mod--shy');
            this.buttonPanorama.classList.remove('mod--shy');
            if (!this.panoramaViewer) {
                this.initializePanoramaViewer();
            }
            if (rerender === true && this.panoramaViewer) {
                this.panoramaViewer.destroy();
                this.initializePanoramaViewer();
            }
        }
    };

    window.addEventListener('orientationchange', (e) => this.render(true));

    this.finalize = () => {
        if (this.threeSixtyViewer) this.threeSixtyViewer.destroy();
        if (this.panoramaViewer) this.panoramaViewer.destroy();
    };

    this.initialize();
}

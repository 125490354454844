export default function Logo({ html }) {
    this.initialize = html => {
        this.logo = document
            .getElementById('header')
            .querySelector('.header__logo');
    };
    this.update = ({ html }) => {
        const currentLogo = html.body.querySelector('#header .header__logo');
        this.logo.href = currentLogo.href;
    };
    // this.finalize = () => {
    // };
    this.initialize(html);
}

const config = {
    componentSelector: '[data-component]',
    componentIdentifier: 'data-component',
    subComponentSelector: '[data-subcomponent]',
    subComponentIdentifier: 'data-subcomponent',
    buiSelector: '[data-bui]',
    buiIdentifier: 'data-bui',
    bookmarkSelector: '[data-bookmark]',
    shoppingCartSelector: '[data-shopping-cart-entry]',
    carPackageShoppingCartSelector: '[data-car-package-shopping-cart-entry]',
    globalSearchApiUrl:
        'https://core.dxpapi.com/api/v1/core/?account_id=6348&auth_key=ycdz4m1u6p20fw5y&domain_key=beresa_de_de&request_id=8069294057475&_br_uid_2=uid%253D8292905588503%253Av%253D11.8%253Ats%253D1567692701848%253Ahc%253D307&url=www.bloomique.com&ref_url=www.bloomique.com&request_type=search&rows=10&start=0&search_type=keyword&fl=fuel_type,title,description,thumb_image,large_image,mileage,pid,sale_price,price,url,brand,first_registration_date',
    restBaseUrl: "restservices/"
};

export default config;

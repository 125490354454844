import Bui from '../../assets/js/Bui';

export default function CockpitTyres({container}) {
	const self = this;
    this.initialize = () => {
		this.container = container;
		this.bui = new Bui({container});
    };
    this.finalize = () => {
		if (self.bui) self.bui.finalize();
    };
    this.initialize();
}

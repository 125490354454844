import Carslider from '../carslider';

export default function Nextbestactions({container, history}) {
    const self = this;
    this.toFinalize = [];
    this.initialize = () => {
        this.carsliders = [];
        this.products = container.querySelector(
            '.next-best-actions-result__products'
        );
        // Carsliders
        if (this.products != null) {
            this.carsliders = [...this.products.querySelectorAll('.carslider')].map(
                slider => {
                    const has360 = slider.querySelector(
                        '.carslider__thumbnail--360'
                    );

                    return new Carslider(slider, {
                        nav: false,
                        thumbItems: 3,
                        mouseDrag: false,
                        thumbAxis: 'vertical',
                        threeSixty: has360 ? true : false,
                    });
                }
            );
        }
    };
    this.finalize = () => {
        if (this.carsliders) {
            this.carsliders.forEach(slider => slider.finalize());
        }
    };
    this.initialize();
}

import GeneralSlider from "../general-content-slider";

export default function NewshubReduced({ container, history }) {
    this.toFinalize = [];

    this.initialize = () => {
        this.slider = new GeneralSlider(container);
        this.slider.initialize();
    };

    this.finalize = () => {
        this.slider.finalize();
    };

    this.initialize();
}
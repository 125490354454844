export default function HeaderCart({container, store}) {
    this.initialize = () => {
        this.cartButton = container.querySelector('.header__bubble-wrapper');
        this.bubble = document.createElement('span');
        this.bubble.classList.add('header__bubble');
        this.bubbleText = document.createElement('span');
        this.bubbleText.classList.add('header__bubble-text');

        this.bubble.appendChild(this.bubbleText);

        this.cartButton.appendChild(this.bubble);
        this.unsubscribe = store.subscribe(this._update);
        this._update();
    };
    this._update = () => {
        if (this.cartButton.dataset.storeId === "favorite_ids") {
            const {bookmarks} = store.getState();
            this.bubbleText.innerText = bookmarks.length;
        } else if (this.cartButton.dataset.storeId === "shopping") {
            const {shoppingCartElements} = store.getState();
            let count = 0;
            for (let [key, value] of Object.entries(shoppingCartElements)) {
                count += value;
            }
            this.bubbleText.innerText = count.toString();
        }
    };
    this.finalize = () => {
        if (this.bubble) {
            this.bubble.parentNode.removeChild(this.bubble);
        }
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    };
    this.initialize();
}


function hasClass(elem, cls) {
    var str = " " + elem.className + " ";
    var testCls = " " + cls + " ";
    return(str.indexOf(testCls) != -1) ;
}

function closest(el, cls) {
    while (el  && el !== document) {
        if (hasClass(el, cls)) return el;
        el = el.parentNode;
    }
    return null;
}

export default function Divider({ container, history }) {

    this.initialize = () => {
        let gridRowParent = closest(container, "grid__row");

        if (gridRowParent) {

            gridRowParent.classList.add("has-divider");

            const gridCell = gridRowParent.querySelector(".grid__cell");

            if (gridCell &&
                gridCell.lastElementChild &&
                gridCell.lastElementChild.classList &&
                gridCell.lastElementChild.classList.contains("divider")
            ) {
                // has-divider-as-last-or-only-child
                gridRowParent.classList.add("hide-divider-for-mobile");
            }
        }
    };

    this.finalize = () => {
    };
    this.initialize();
}

import './iframe-resizer-davidjbradshaw'

let getLocation = function (href) {
    let l = document.createElement("a");
    l.href = href;
    return l;
};

export default function Iframe({container, history, config}) {

    this.configLocation;

    this.iframe;

    this.resizeHandler;


    this.scrolltoTopOfIframe = () => {
        const element = this.iframe;
        if (!!element && !!element.getBoundingClientRect()) {
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 120;
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: 'smooth' // smooth scroll
            });
        }
    };

    this.handleIframeMsgDigitalesautohaus = (event) => {

        let maybeSizeOrRouteChanged = event.data;

        if ((typeof maybeSizeOrRouteChanged === "number")
            && (maybeSizeOrRouteChanged - 40 > 0)) {

            this.iframe.height = maybeSizeOrRouteChanged + 40;
        }

        if (maybeSizeOrRouteChanged == "routeChanged") {
            this.scrolltoTopOfIframe();
        }
    };

    this.handleIframeMsgGuidecom = (event) => {
        let eventName = event.data && event.data[0];
        if (!!eventName && (eventName === 'setIframeHeight')) {
            this.iframe.height = event.data[1];
        }
    };

    this.receiveMessage = (event) => {
        if ((event.origin === this.configLocation.origin) && (event.source === this.iframe.contentWindow)) {
            switch (event.origin) {
                case 'https://booking.digitalesautohaus.de':
                    this.handleIframeMsgDigitalesautohaus(event);
                    break;

                case 'https://connect.guidecom.de':
                    this.handleIframeMsgGuidecom(event);
                    break;

                default:
                    let eventName = event.data[0];

                    if (Array.isArray(eventName) && (eventName === 'setIframeHeight')) {

                        // This is the case where the event is of unambiguous type (setIframeHeight).
                        this.iframe.height = event.data[1];

                    } else {

                        // This is the  standard case where the event tells us the new size of the iframe.
                        this.iframe.height = event.data;

                    }
            }
        }
    };

    this.initialize = () => {

        let ifFrameSrc = config && config.origin;

        if (!ifFrameSrc) {
            console.error('Iframe config malformed', config);
            return;
        }

        this.iframe = container.querySelector("iframe");
        const locationHref = window && window.location && window.location.href;

        if (locationHref) {
            ifFrameSrc = addOrReplaceQueryParam(ifFrameSrc, "parent_url", locationHref);
        }

        this.configLocation = getLocation(ifFrameSrc);
        this.iframe.src = this.configLocation;

        // Resizing Logic
        if (config.useBradshawResizer) {
            // Resizing Jobs "guidecom"
            iFrameResize(
                {
                    log: false,
                    enablePublicMethods     : true, // Enable methods within iframe hosted page
                    heightCalculationMethod : 'lowestElement',
                },
                '#jobDetailIframe'
            )
        } else {
            // Resizing Message Based
            window.addEventListener("message", this.receiveMessage, false);
        }
    };

    this.finalize = () => {
        window.removeEventListener("message", this.receiveMessage, false);
    };

    this.initialize();
}

function addOrReplaceQueryParam(url, key, value) {
    let tempArray = url.split("?");
    let baseURL = tempArray[0];
    let aditionalQueryParams = tempArray[1];

    let newQueryList = [(key + "=" + value)];

    if (aditionalQueryParams) {
        let oldQueryList = aditionalQueryParams.split("&");

        for (var i = 0; i < oldQueryList.length; i++) {
            let currentQuery = oldQueryList[i];
            let currentKeyValueOfQuery = currentQuery.split("=");
            if (currentKeyValueOfQuery[0] !== key) {
                newQueryList.push(currentQuery)
            }
        }
    }
    return baseURL + "?" + newQueryList.join("&")
}

import appConfig from '../../assets/js/config.js';
import Bui from '../../assets/js/Bui';
import {addEvent} from 'buikit/src/assets/js/utils/eventHandling';
import {FormValidator, getFieldValue,} from 'buikit/src/assets/js/utils/formHandling';
import Leasing from '../leasing/leasing-subcomponent';
import Carslider from "../carslider";
import {clearShoppingCartItems} from "../../assets/js/actions";

export default function Checkout({container, history, config, store}) {
    const self = this;
    this.initialize = () => {
        this.container = container;
        this.leasingSection = container.querySelector(
            '.leasing[data-subcomponent]'
        );
        this.namespace = config.namespace;
        this.eventListeners = [];
        this.bui = new Bui({container});
        this.checkoutForm = container.querySelector('form.checkout__form');
        this.errorMessages =
            window.beresa && window.beresa.ui && window.beresa.ui.errorMessages
                ? window.beresa.ui.errorMessages
                : {};
        if (this.leasingSection) {
            // try, because JSON.parse can crash if somebody put in unparseable code
            try {
                const leasingConfig = JSON.parse(
                    this.leasingSection.getAttribute(
                        appConfig.subComponentIdentifier
                    )
                );
                this.leasingCalculator = new Leasing({
                    container: this.leasingSection,
                    history,
                    config: leasingConfig,
                });
            } finally {
            }
        }

        if (this.checkoutForm) {
            this.step = this.checkoutForm.getAttribute('data-step');
            if (this.step === 'shipment') {
                this.shipmentSelectors = this.container.querySelectorAll(
                    '[data-shipment="selector"] input[type="radio"]'
                );
                this.shipmentPickupRadio = this.container.querySelector(
                    '[data-shipment="selector"] input[type="radio"]'
                );
                this.shipmentForms = this.container.querySelectorAll(
                    '[data-shipment="forms"] > .shipment__form'
                );
                this.shipmentLocations = Array.from(
                    this.container.querySelectorAll(
                        '[data-shipment="forms"] [data-location]'
                    )
                );

                if (this.shipmentSelectors && this.shipmentSelectors.length) {
                    this.shipmentSelectors.forEach(radio =>
                        this.eventListeners.push(
                            addEvent(
                                radio,
                                'click',
                                this.handleShipmentSelection
                            )
                        )
                    );
                }
            }
            this.handleFormValidation();
        }

        // Initialize all carsliders
        this.carsliders = [...container.querySelectorAll('.carslider')].map(
            slider => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: true,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'horizontal',
                    threeSixty: has360 ? true : false,
                });
            }
        );
    };

    this.handleFormValidation = () => {
        if (this.formValidator) {
            this.formValidator.finalize();
        }
        const scrollOnError = () => {
            window.scroller.clear();
            const y =
                this.checkoutForm.getBoundingClientRect().top +
                window.pageYOffset -
                80;
            window.scroller.top(y);
        };
        if (this.step === 'intro') {
            this.formValidator = new FormValidator(
                this.checkoutForm,
                [
                    {
                        name: 'customer_type',
                        rules: ['required'],
                    },
                ],
                {customMessages: this.errorMessages, onerror: scrollOnError}
            );
        } else if (this.step === 'shipment' && this.shipmentPickupRadio) {
            // form validation for shipment step
            const isPickup = getFieldValue(this.shipmentPickupRadio);
            if (isPickup) {
                this.formValidator = new FormValidator(
                    this.checkoutForm,
                    [
                        {
                            name: 'location',
                            rules: ['required'],
                        },
                    ],
                    {
                        customMessages: this.errorMessages,
                        onerror: scrollOnError,
                    }
                );
            } else {
                // check other form
            }
        } else if (this.step === 'data') {
            const fieldsToTest = [
                'salutation',
                'firstname',
                'lastname',
                'street',
                'houseNo',
                'zip',
                'city',
                'phone',
                'birthday',
                'birthmonth',
                'birthyear',
                'vatid',
            ].filter(f => {
                return this.checkoutForm.querySelector(`[name='${f}']`)
            }).map(n => ({
                name: n,
                rules: ['required'],
            }));

            fieldsToTest.push({
                name: 'email',
                rules: ['required', 'email'],
            });
            this.formValidator = new FormValidator(
                this.checkoutForm,
                fieldsToTest,
                {customMessages: this.errorMessages, onerror: scrollOnError}
            );
        } else if (this.step === 'success') {
            // clear shopping cart entries in redux store
            const {shoppingCartElements} = store.getState();
            if (shoppingCartElements && !isEmpty(shoppingCartElements)) {
                store.dispatch(clearShoppingCartItems());
            }
        }
    };

    function isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    this.handleShipmentLocationChange = () => {
        const location = getFieldValue(this.shipmentLocationSelect);
        if (!this.shipmentLocations || !this.shipmentLocations.length) {
            return;
        }
        this.shipmentLocations.forEach(l => {
            if (l.getAttribute('data-location') == location) {
                l.classList.remove('location--hidden');
            } else {
                l.classList.add('location--hidden');
            }
        });
    };

    this.handleShipmentSelection = e => {
        const currentRadioValue = e.currentTarget.value;

        // Change active checkbox label
        const labels = this.container.querySelectorAll(
            '[data-shipment="selector"] label'
        );

        labels.forEach(label => {
            if (label.classList.contains(`checkbox--${currentRadioValue}`)) {
                label.classList.remove('mod--shy');
            } else {
                label.classList.add('mod--shy');
            }
        });

        // Change active visible form
        this.shipmentForms.forEach(form => {
            if (form.classList.contains(`${currentRadioValue}`)) {
                form.classList.add('active');
            } else {
                form.classList.remove('active');
            }
        });
        this.handleFormValidation();
    };

    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(listener => {
                listener.remove();
            });
        }
        if (this.carsliders) {
            this.carsliders.forEach(slider => slider.finalize());
        }
        if (this.formValidator) {
            this.formValidator.finalize();
        }
        if (this.leasingCalculator) {
            this.leasingCalculator.finalize();
        }
        if (self.bui) self.bui.finalize();
    };
    this.initialize();
}

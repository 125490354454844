import config from './config';
import Collapse from 'buikit/src/ui-components/01-atoms/collapse';
import Scrollwatcher from 'buikit/src/ui-components/01-atoms/scrollwatcher';
import Tabs from 'buikit/src/ui-components/02-molecules/tabs';
import Modal from 'buikit/src/ui-components/02-molecules/modal';
import RangeSlider from 'buikit/src/ui-components/01-atoms/form/rangeSlider';
import InputSelect from 'buikit/src/ui-components/01-atoms/form/inputSelect';
import InputDate from 'buikit/src/ui-components/01-atoms/form/inputDate';

const buiClasses = {
  Collapse,
  InputSelect,
  Modal,
  RangeSlider,
  Tabs,
  InputDate,
  Scrollwatcher,
};

export default function Bui({ container, collapseStatus }) {
  this.buis = [];
  const subComponents = [
    ...container.querySelectorAll(config.subComponentSelector),
  ];
  Array.from(container.querySelectorAll(config.buiSelector))
    .filter((b) => {
      for (let i = 0, len = subComponents.length; i < len; i++) {
        if (subComponents[i].contains(b)) {
          return false;
        }
      }
      return true;
    })
    .forEach((bui) => {
      // try, because JSON.parse can crash if somebody put in unparseable code
      try {
        const buiConfig = JSON.parse(bui.getAttribute(config.buiIdentifier));
        if (collapseStatus && collapseStatus[bui.getAttribute('id')]) {
          buiConfig['expanded'] = true;
        }
        if (buiClasses[buiConfig.name]) {
          const buiObject = {
            dom: bui,
            name: buiConfig.name,
            handler: new buiClasses[buiConfig.name](bui, buiConfig),
          };
          this.buis.push(buiObject);
        }
      } finally {
      }
    });
  this.getUiComponents = () => {
    return this.buis;
  };
  this.finalize = () => {
    if (this.buis.length) {
      this.buis.forEach((bui) => {
        if (bui.handler && typeof bui.handler.finalize === 'function') {
          bui.handler.finalize();
        }
      });
    }
  };
}

import {
    addEvent,
    addLiveEvent,
} from 'buikit/src/assets/js/utils/eventHandling';
import { joinQueryOptions } from 'buikit/src/assets/js/utils/urlHandling';
import {
    getFieldValue,
    getFormData,
    resetFieldValue,
} from 'buikit/src/assets/js/utils/formHandling';
import Collapse from 'buikit/src/ui-components/01-atoms/collapse';
import Bui from '../../assets/js/Bui';
import queryString from 'query-string';
import Carslider from '../carslider';
import firePixelEvent from '../../assets/js/utils/BrPixel';

export default function Flexibleproductlist({ container, history }) {
    const self = this;
    this.toFinalize = [];
    this.initialize = () => {
        this.eventListeners = [];
        this.state = {
            sort: '',
            filterCollapse: false,
        };
        this.collapses = container.querySelectorAll('.collapse');
        this.expandedCollapses = {};
        this.collapses.forEach((col) => {
            if (col.getAttribute('id') != null) {
                if (col.getAttribute('aria-expanded') === 'true') {
                    this.expandedCollapses[col.getAttribute('id')] = true;
                } else {
                    this.expandedCollapses[col.getAttribute('id')] = false;
                }
            }
        });
        this.eventListeners.push(
            addLiveEvent(
                '.collapse',
                'click',
                (event) => {
                    const collapseAble =
                        event.target.parentNode.getAttribute('id');
                    if (collapseAble != null) {
                        const expanded =
                            event.target.parentNode.getAttribute(
                                'aria-expanded'
                            );
                        this.expandedCollapses[collapseAble] =
                            expanded === 'true';
                    }
                },
                container
            )
        );
        this.setState = (newState) => {
            const oldState = this.state;
            this.state = {
                ...this.state,
                ...newState,
            };
            this.onStateChange(oldState, this.state);
            return this.state;
        };
        this.facet = container.querySelector('.flexibleproductlist-facet');
        this.form = null;
        if (this.facet != null) {
            this.form = this.facet.querySelector('form');
            this.facetBui = new Bui({
                container: this.facet,
                collapseStatus: this.expandedCollapses,
            });
        }
        this.result = container.querySelector('.flexibleproductlist-result');
        if (this.result != null) {
            this.headline = this.result.querySelector(
                '.flexibleproductlist-result__hl'
            );
            this.tagBar = this.result.querySelector(
                '.flexibleproductlist-result__tag-bar'
            );
            this.pagination = this.result.querySelector(
                '.flexibleproductlist-result__pagination'
            );
            this.products = this.result.querySelector(
                '.flexibleproductlist-result__products'
            );
            this.sortButton = this.result.querySelector('[name="sort"]');

            if (this.sortButton) {
                this.eventListeners.push(
                    addEvent(this.sortButton, 'change', () =>
                        this.setState({ sort: getFieldValue(this.sortButton) })
                    )
                );
            }
        }
        var scrollToTop = true;
        this.scrollConfig = container.querySelector(
            '.flexibleproductlist__scroll-config'
        );
        if (this.scrollConfig != null) {
            scrollToTop = this.scrollConfig.dataset.facetScrollToTop === 'true';
        }
        if (scrollToTop) {
            this.eventListeners.push(
                addLiveEvent('.s-pagination__list a', 'click', function () {
                    window.scroller.top(0);
                })
            );
        }
        this.initializeFacets();

        // Carsliders
        if (this.products != null) {
            this.carsliders = [
                ...this.products.querySelectorAll('.carslider'),
            ].map((slider) => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: false,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'vertical',
                    threeSixty: has360 ? true : false,
                });
            });
        }

        // if mobile version make the facet search collapsable
        this.watcher = window.dimensionWatcher.addWatch({
            namespace: 'flexibleproductlist',
            matchCondition([innerWidth, innerHeight]) {
                return innerWidth < 1024;
            },
            onappear() {
                self.setState({
                    filterCollapse: true,
                });
            },
            ondisappear() {
                self.setState({
                    filterCollapse: false,
                });
            },
        });

        this.update({ container });
        this.eventListeners.push(
            addLiveEvent(
                '.flexibleproductlist-result__tag',
                'click',
                handleTag,
                container
            )
        );
        this.eventListeners.push(
            addLiveEvent(
                '.flexibleproductlist-result__remove-tag',
                'click',
                () => {
                    self.formInputs.forEach((element) => {
                        if (element.name !== 'budget')
                            resetFieldValue(element, true);
                    });
                    if (self.formInputs && self.formInputs.length > 0) {
                        self.formInputs[0].dispatchEvent(new Event('change'));
                    } else {
                        this.pushData();
                    }
                },
                container
            )
        );
    };
    this.initializeFacets = () => {
        const params = queryString.parse(location.search);
        if (this.form != null) {
            this.formInputs = this.form.querySelectorAll('[name]');
            this.formInputs.forEach((element) => {
                if (!element.getAttribute('eventListenerAttached')) {
                    if (element.name === 'financingMonthlyRate[]') {
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                this.form.querySelector(
                                    '[name="budget"][value="salesprice[]"]'
                                ).checked = 'checked';
                            })
                        );
                        // reset range slider if no param has been found in the url
                        if (!params['financingMonthlyRate']) {
                            element.value = '';
                        }
                    }
                    if (element.name === 'salesprice[]') {
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                this.form.querySelector(
                                    '[name="budget"][value="financingMonthlyRate[]"]'
                                ).checked = 'checked';
                            })
                        );
                    }
                    if (element.name === 'previousOwner') {
                        // reset range slider if no param has been found in the url
                        if (!params['previousOwner']) {
                            element.value = '';
                        }
                    }
                    if (element.name === 'cat_brands') {
                        // reset range slider if no param has been found in the url
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                let modelSelection = this.form.querySelector(
                                    '[name="cat_models"]'
                                );
                                modelSelection.options.forEach((option) => {
                                    option.selected = false;
                                });
                            })
                        );
                    }
                    if (element.name === 'color') {
                        this.eventListeners.push(
                            addEvent(element, 'click', () => {
                                let colorRadios =
                                    this.form.querySelectorAll(
                                        '[name="color"]'
                                    );
                                colorRadios.forEach((radio) => {
                                    if (
                                        radio.getAttribute('value') ===
                                        element.getAttribute('value')
                                    ) {
                                        radio.setAttribute(
                                            'checked',
                                            'checked'
                                        );
                                    } else {
                                        radio.removeAttribute('checked');
                                    }
                                });
                            })
                        );
                    }
                    if (element.type === 'text') {
                        this.eventListeners.push(
                            addEvent(element, 'input', (e) =>
                                this.handleTextInputChange(e)
                            )
                        );
                    } else {
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                if (
                                    self.state.filterCollapse &&
                                    self.filterCollapse
                                ) {
                                    self.filterCollapse.close();
                                }
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                this.pushData();
                            })
                        );
                    }

                    element.setAttribute('eventListenerAttached', true);
                }
            });
        }
    };
    this.onStateChange = (prevState) => {
        const { sort, filterCollapse } = this.state;
        if (prevState.sort !== sort) {
            this.pushData();
        }
        if (prevState.filterCollapse !== filterCollapse) {
            if (
                this.filterCollapse &&
                typeof this.filterCollapse.finalize === 'function'
            ) {
                this.filterCollapse.finalize();
                this.facet.classList.remove('mod--collapseable');
            }
            if (filterCollapse) {
                this.facet.classList.add('mod--collapseable');
                this.filterCollapse = new Collapse(this.facet, {
                    switcher: '.flexibleproductlist-facet__hl',
                    flyout: '.flexibleproductlist-facet__wrapper',
                    content: '.flexibleproductlist-facet__form',
                });
            }
        }
    };
    this.handleTextInputChange = (e) => {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.pushData();
        }, 1000);
    };
    this.pushData = () => {
        const exclude = Array.from(
            this.form.querySelectorAll('[name="budget"]')
        ).reduce((collection, current) => {
            const value = getFieldValue(current);
            if (value) {
                collection.push(value);
            }
            return collection;
        }, []);
        exclude.push('budget');
        const data = getFormData(this.form, { exclude });
        joinQueryOptions(data);
        if (this.state.sort) {
            data.sort = this.state.sort;
        }
        history.push({
            pathname: this.form.getAttribute('action'),
            search: queryString.stringify(data),
        });

        // fire br pixel on facet change
        firePixelEvent();
    };
    const handleTag = (e) => {
        const key = e.target.parentElement.getAttribute('data-filter-key');
        const filterElement = self.facet.querySelector('[name^="' + key + '"]');
        if (filterElement) {
            if (filterElement.name.endsWith('[]')) {
                const groupElements = self.facet.querySelectorAll(
                    '[name^="' + key + '"]'
                );
                groupElements.forEach((element) => {
                    resetFieldValue(element, true);
                });
            } else {
                resetFieldValue(filterElement, true);
            }
            filterElement.dispatchEvent(new Event('change'));
        } else {
            this.pushData();
        }
    };
    this.beforeUpdate = () => {
        // do stuff like finalizing all temporary components
        if (this.resultBui) {
            this.resultBui.finalize();
        }

        // TODO remove tag events
    };
    this.update = ({ container }) => {
        const newProducts = container.querySelector(
                '.flexibleproductlist-result__products'
            ),
            newTagBar = container.querySelector(
                '.flexibleproductlist-result__tag-bar'
            ),
            newPagination = container.querySelector(
                '.flexibleproductlist-result__pagination'
            ),
            newHeadline = container.querySelector(
                '.flexibleproductlist-result__hl'
            );
        if (this.headline != null) {
            this.headline.replaceWith(newHeadline);
        }
        this.headline = newHeadline;
        if (this.tagBar != null) {
            this.tagBar.replaceWith(newTagBar);
        }
        this.tagBar = newTagBar;
        if (this.pagination != null) {
            this.pagination.replaceWith(newPagination);
        }
        this.pagination = newPagination;
        if (this.products != null) {
            this.products.replaceWith(newProducts);
            this.products = newProducts;
            this.resultBui = new Bui({
                container: this.result,
                collapseStatus: this.expandedCollapses,
            });
            this.carsliders = [
                ...this.products.querySelectorAll('.carslider'),
            ].map((slider) => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: false,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'vertical',
                    threeSixty: has360 ? true : false,
                });
            });
        }

        const newFacet = container.querySelector('.flexibleproductlist-facet');
        if (newFacet != null) {
            this.facet.replaceWith(newFacet);
            this.facet = newFacet;

            this.form = this.facet.querySelector('form');
            this.facetBui.finalize();
            this.facetBui = new Bui({
                container: this.facet,
                collapseStatus: this.expandedCollapses,
            });
            this.initializeFacets();
        }
    };
    this.finalize = () => {
        if (this.facetBui && typeof this.facetBui.finalize === 'function') {
            this.facetBui.finalize();
        }
        if (this.resultBui && typeof this.resultBui.finalize === 'function') {
            this.resultBui.finalize();
        }
        if (this.watcher) {
            window.dimensionWatcher.removeWatch(this.watcher);
        }
        if (this.eventListeners.length) {
            this.eventListeners.forEach((event) => event.remove());
        }
        if (this.carsliders) {
            this.carsliders.forEach((slider) => slider.finalize());
        }
    };
    this.initialize();
}

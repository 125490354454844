import Bui from '../../assets/js/Bui';

export default function SantanderWidget({container, history, config}) {
    const self = this;
    const scriptTagId = 'santander-widget-script-tag';
    const observers = [];
    this.initialize = () => {
        this.container = container;
        this.bui = new Bui({container});

        let akzValue = "008";
        if (config && config["santanderAkz"]) {
            akzValue = config["santanderAkz"];
        }

        this.removeSantanderWidgetFromDom();

        const s = document.createElement('script');
        s.id = scriptTagId;
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://acapi.santander.de/script/UniversalPlugin?AKZ=' + akzValue;
        s.onload = function () {
            const observerConfig = {
                attributes: true,
                childList: true,
                characterData: true
            };
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
                        mutation.addedNodes.forEach((node) => {
                            if (node.classList && node.classList.contains("san-widget-floating")
                            ) {
                                const santanderWidget = window.document.querySelector(".san-widget-floating");
                                const santanderButtonSpan = window.document.querySelector("#santander-widget__button-span");
                                if (santanderWidget && santanderButtonSpan) {
                                    santanderWidget.id = "santander-widget";
                                    santanderButtonSpan.before(santanderWidget);
                                    const floatWidgetButton = santanderWidget.querySelector("#san-float-widget-btn");
                                    if (floatWidgetButton.hasChildNodes()) {
                                        floatWidgetButton.childNodes.forEach(child => floatWidgetButton.removeChild(child));
                                    }
                                    santanderButtonSpan.className = "button mod--block";
                                    floatWidgetButton.appendChild(santanderButtonSpan);
                                    floatWidgetButton.parentElement.className = "santander-widget__button-parent-div";
                                }
                            }
                        });
                    }
                });
            });
            observer.observe(window.document.body, observerConfig);
            observers.push(observer);
        };
        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    };

    this.removeSantanderWidgetFromDom = () => {
        let widgetsOnPage = window.document.querySelector('.san-widget-floating');
        if (widgetsOnPage) {
            if (Array.isArray(widgetsOnPage)) {
                widgetsOnPage.forEach(el => el.remove());
            } else {
                widgetsOnPage.remove();
            }
        }
    }

    this.finalize = () => {
        if (self.bui) self.bui.finalize();
        let widgetScriptTagElement = window.document.querySelector("#" + scriptTagId);
        if (widgetScriptTagElement) {
            widgetScriptTagElement.remove();
        }
        this.removeSantanderWidgetFromDom();
        if (observers && observers.length > 0) {
            observers.forEach(observer => observer.disconnect());
        }
        // window.stop();
    };
    this.initialize();
}

class Headermenu {
    constructor(menu, hamburger) {
        this.header = document.querySelector('#header');
        this.menu = document.querySelector(menu);
        this.hamburger = document.querySelector(hamburger);
        this.parentMenuItems = this.menu.querySelectorAll(
            '.menu__item--parent'
        );
        this.menuItemsWithChilds = this.menu.querySelectorAll(
            '.menu__item.menu__item--has-childs > .menu__link'
        );
        this.menuItemsWithoutChilds = this.menu.querySelectorAll(
            '.menu__item:not(.menu__item--has-childs) > .menu__link'
        );
        this.run();
    }

    toggleMenu(close) {
        if (close) {
            this.menu.classList.remove('visible');
            this.hamburger.classList.remove('active');
            return;
        }

        this.menu.classList.toggle('visible');
        this.hamburger.classList.toggle('active');

        if (this.menu.classList.contains('visible')) {
            this.setMobileMaxHeight();
        }
    }

    closeAllMenuItems() {
        this.parentMenuItems.forEach((item) => item.classList.remove('open'));
        this.toggleMenu(true);
    }

    closeMenuItems(item) {
        this.menuItemsWithChilds.forEach((it) => {
            if (it !== item) {
                it.parentElement.classList.remove('open');
            }
        });
    }

    openMenuItem(item) {
        const parent = item.parentElement;
        parent.classList.contains('open')
            ? parent.classList.remove('open')
            : parent.classList.add('open');
    }

    getCurrentlyFocusedTree(item) {
        let openTree = [];
        let currParent = item.parentNode;
        if(currParent) {
            while (currParent) {
                if (currParent.classList.contains('menu')) {
                    currParent = false;
                }
                if (currParent && currParent.nodeType === 1) {
                    openTree.push(currParent);
                }
                currParent = currParent.parentNode;
            }
        }
        return openTree;
    }

    closeAllSiblingsAndToggleThisOne(item) {
        let currentlyFocusedTree =  this.getCurrentlyFocusedTree(item);
        let currentlyOpenedMenuItems = this.menu.querySelectorAll(
            '.menu__item.open'
        );
        currentlyOpenedMenuItems.forEach(function (openedMenuItem, index) {
            if(!currentlyFocusedTree.includes(openedMenuItem)) {
                openedMenuItem.style.color = "red";
                if (openedMenuItem.classList.contains('open')) {
                    openedMenuItem.classList.remove('open');
                }
            }
        });
        this.openMenuItem(item);
    }

    addEventListeners() {
        this.hamburger.addEventListener('click', (e) => this.toggleMenu());
        this.menuItemsWithChilds.forEach((item) => {
            item.addEventListener('click', (event) => {
                event.preventDefault();
                this.closeAllSiblingsAndToggleThisOne(item);
                event.stopPropagation();
            });
        });
        this.menuItemsWithoutChilds.forEach((item) => {
            item.addEventListener('click', (event) => {
                this.closeAllMenuItems();
            });
        });
    }

    setMobileMaxHeight() {
        const headerGridHeight = this.header.querySelector('.header__grid')
            .offsetHeight;
        const menuListLevelZero = this.header.querySelector(
            '.header__navigation.visible .menu__list--level-0'
        );

        menuListLevelZero.style.height = `calc(100vh - ${headerGridHeight}px)`;
    }

    run() {
        this.addEventListeners();
    }
}

if (document.querySelector('.header__navigation')) {
    const menu = new Headermenu('.header__navigation', '.header__hamburger');
}

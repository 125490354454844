import {
    addEvent,
    addLiveEvent,
} from 'buikit/src/assets/js/utils/eventHandling';
import {
    getFormData,
    resetFieldValue,
} from 'buikit/src/assets/js/utils/formHandling';
import queryString from 'query-string';

export default function CockpitRegistration({ container, history }) {
    const self = this;
    this.toFinalize = [];
    this.initialize = () => {
        this.eventListeners = [];

        this.form = container.querySelector('.register-me');

        if (!!this.form) {
            this.eventListeners.push(
                addEvent(this.form, 'submit', (e) => {
                    let params = getFormData(this.form) || {};

                    e.preventDefault();
                    if (!!params.email) {
                        return false;
                    }
                    if (!!params.first) {
                        const field = document.getElementById('email');
                        field.setAttribute('value', params.first);
                    }

                    e.currentTarget.submit();
                })
            );
        }
    };

    // this.handleTextInputChange = e => {
    //     clearTimeout(this.delayTimer);
    //     this.delayTimer = setTimeout(() => {
    //         this.pushData();
    //     }, 1000);
    // };

    // const handleTag = function(e) {
    //     const key = this.getAttribute('data-filter-key');
    //
    //     //console.log(filterElement);
    //     if (filterElement.tagName == 'INPUT') {
    //         filterElement.dispatchEvent(new Event('input'));
    //     } else {
    //         filterElement.dispatchEvent(new Event('change'));
    //     }
    // };

    this.beforeUpdate = () => {};

    this.update = ({ container }) => {};

    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach((event) => event.remove());
        }
    };

    this.initialize();
}

export const VISIBILITY_LOCAL_STORAGE_KEY = 'aiSearchVisibility';
export const VISIBILITY_PERCENTAGE = () => {
    if (globalThis.aiSearchWidgetConfig
        && globalThis.aiSearchWidgetConfig.config
        && globalThis.aiSearchWidgetConfig.config.visibility) {
        console.log("globalThis.aiSearchWidgetConfig.config.visibility", globalThis.aiSearchWidgetConfig.config.visibility)
        return globalThis.aiSearchWidgetConfig.config.visibility;
    }
    console.log("use default visibility percentage")
    return 20;
}

export function isVisible() {
    try {
        if (globalThis.aiSearchWidgetConfig
            && globalThis.aiSearchWidgetConfig.config
            && globalThis.aiSearchWidgetConfig.config.enabled === false) {
            console.log("AISearch not visible because its globally disabled");
            return false;
        }
        const aiIsVisibleByQueryParam = getAiIsVisibleByQueryParam();
        if (aiIsVisibleByQueryParam && aiIsVisibleByQueryParam === 'true') {
            saveVisibilityUntilTomorrow(true);
            return true;
        } else if (aiIsVisibleByQueryParam && aiIsVisibleByQueryParam === 'false') {
            saveVisibilityUntilTomorrow(false);
            return false;
        }

        const visibilityLocalStorage = localStorage.getItem(VISIBILITY_LOCAL_STORAGE_KEY);
        const visibility = JSON.parse(visibilityLocalStorage);
        const expiresAt = visibility ? visibility.until : null;
        if (!expiresAt) {
            return randomizeVisibility();
        }
        const isExpired = new Date(expiresAt) <= new Date();
        if (isExpired) {
            return randomizeVisibility();
        } else {
            return visibility.isVisible;
        }
    } catch (e) {
        localStorage.removeItem(VISIBILITY_LOCAL_STORAGE_KEY);
        return randomizeVisibility();
    }
}

export function getAiIsVisibleByQueryParam() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('aiIsVisible');
}

export function randomizeVisibility() {
    const randomNumber = Math.random() * 100;
    if (randomNumber < VISIBILITY_PERCENTAGE()) {
        saveVisibilityUntilTomorrow(true);
        return true;
    } else {
        saveVisibilityUntilTomorrow(false);
        return false;
    }
}

export function saveVisibilityUntilTomorrow(isVisible) {
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    localStorage.setItem(VISIBILITY_LOCAL_STORAGE_KEY, JSON.stringify({
        "isVisible": isVisible,
        "until": expiresAt.toISOString()
    }));
}
import ScrollBooster from './ourscrollbooster';

export default function Timeline({ container, history }) {
    this.initialize = () => {
        const self = this;

        this.state = {
            position: {
                x: 0,
                y: 0,
            },
            max_x_position: 0,
            balance: 0,
            collision: false,
        };

        this.setState = (newState, cb) => {
            this.state = {
                ...this.state,
                ...newState,
            };
            this.checkSliderButtons();
            if (cb) return cb();
        };

        this.container = container;
        this.sliderContainer = this.container.querySelector(
            '.timeline__slider'
        );
        this.sliderContent = this.container.querySelector(
            '.timeline__slider__content'
        );
        this.contentContainer = this.container.querySelector(
            '.timeline__content'
        );
        this.dateItems = this.sliderContainer.querySelectorAll(
            '[data-item-date]'
        );
        this.contentItems = this.contentContainer.querySelectorAll(
            '[data-item-content]'
        );
        this.prevButton = this.container.querySelector(
            '.timeline__button--prev'
        );
        this.nextButton = this.container.querySelector(
            '.timeline__button--next'
        );
        this.ranges = this.container.querySelectorAll('.timeline__range');
        this.maxWidth = this.getContentMaxWidth(this.ranges);
        this.viewportWidth = this.sliderContent.offsetWidth;
        this.offset = 335;

        this.slider = new ScrollBooster({
            viewport: this.sliderContainer,
            content: this.sliderContent,
            scrollMode: 'transform',
            direction: 'horizontal',
            bounce: true,
            pointerMode: 'all',
            onClick: function(state, event) {
                this.handleSliderClick(event);
            }.bind(this)
        });

        this.prevButton.addEventListener('click', e => this.goPrev());
        this.nextButton.addEventListener('click', e => this.goNext());

        this.setFirstSliderState();
    };

    this.getContentMaxWidth = items => {
        let width = 0;
        items.forEach(item => (width = width + item.offsetWidth));
        return width;
    };

    this.setFirstSliderState = () => {
        const { position } = this.slider.getState();
        this.setState({
            position,
            max_x_position: this.maxWidth - this.viewportWidth,
        });
        let contentItemsHeights = [];
        this.contentItems.forEach(item => {
            contentItemsHeights.push(item.offsetHeight);
            if (item.classList.contains('active')) return;
            item.style.maxHeight = 0;
        });
        const highestItem = Math.max(...contentItemsHeights);
        this.contentContainer.style.height = `${highestItem}px`;
    };

    this.calculateSliderState = (direction, cb) => {
        const { position } = this.slider.getState();
        const dir = direction || next;
        let next_x_position = 0;
        let collision = false;
        let balance = 0;

        if (dir === 'prev') {
            next_x_position = position.x - this.offset;
            collision = next_x_position >= 0 ? false : true;
            balance = this.state.max_x_position - next_x_position;

            if (collision === true) {
                next_x_position = 0;
            }
        }

        if (dir === 'next') {
            next_x_position = position.x + this.offset;
            collision =
                next_x_position <= this.state.max_x_position - this.offset
                    ? false
                    : true;
            balance = this.state.max_x_position - next_x_position;

            if (collision === true) {
                next_x_position = this.state.max_x_position;
            }
        }

        this.setState({
            position: {
                x: next_x_position,
                y: 0,
            },
            collision,
            balance,
        });

        if (cb) return cb();
    };

    this.goNext = () => {
        this.calculateSliderState('next', () => {
            this.slider.scrollTo({
                x: this.state.position.x,
                y: this.state.position.y,
            });
        });
    };

    this.goPrev = () => {
        this.calculateSliderState('prev', () => {
            this.slider.scrollTo({
                x: this.state.position.x,
                y: this.state.position.y,
            });
        });
    };

    this.checkSliderButtons = () => {
        if (this.state.position.x > 0) {
            this.prevButton.style.display = 'block';
        } else {
            this.prevButton.style.display = 'none';
        }

        if (this.state.position.x >= this.state.max_x_position) {
            this.nextButton.style.display = 'none';
        } else {
            this.nextButton.style.display = 'block';
        }
    };

    this.setActiveItem = (dateItem, contentItem) => {
        this.contentItems.forEach(item => {
            if (item !== contentItem) {
                item.classList.remove('active');
                item.style.maxHeight = 0;
                contentItem.style.maxHeight = null;
            } else if (item === contentItem) {
                setTimeout(() => {
                    contentItem.classList.add('active');
                }, 250);
            }
        });
        this.dateItems.forEach(item => {
            if (item !== dateItem) {
                item.classList.remove('active');
            } else if (item === dateItem) {
                dateItem.classList.add('active');
            }
        });
    };

    this.handleSliderClick = e => {
        if (e.target.matches('[data-item-date]')) {
            const itemNumber = e.target.dataset.itemDate;
            const contentItem = this.contentContainer.querySelector(
                `[data-item-content="${itemNumber}"]`
            );
            this.setActiveItem(e.target, contentItem);
        }
    };

    this.finalize = () => {
        if (this.slider) {
            this.slider.destroy();
        }
    };

    this.initialize();
}

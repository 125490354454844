import {addEvent, addLiveEvent,} from 'buikit/src/assets/js/utils/eventHandling';
import {joinQueryOptions} from 'buikit/src/assets/js/utils/urlHandling';
import {getFieldValue, getFormData, resetFieldValue,} from 'buikit/src/assets/js/utils/formHandling';
import Collapse from 'buikit/src/ui-components/01-atoms/collapse';
import Bui from '../../assets/js/Bui';
import queryString from 'query-string';
import Carslider from '../carslider';
import firePixelEvent from '../../assets/js/utils/BrPixel';
import {isVisible} from "../../assets/js/utils/AISearchVisibilityController";

export default function Productsearch({container, history, store}) {
    const self = this;
    this.toFinalize = [];
    this.initialize = () => {
        this.eventListeners = [];
        this.state = {
            sort: '',
            filterCollapse: false,
        };
        globalThis.aiSearchWidgetConfig.addListener(this.handleAISearchVisibility);
        this.collapses = container.querySelectorAll('.collapse');
        this.expandedCollapses = {};
        this.collapses.forEach((col) => {
            if (col.getAttribute('id') != null) {
                if (col.getAttribute('aria-expanded') === 'true') {
                    this.expandedCollapses[col.getAttribute('id')] = true;
                } else {
                    this.expandedCollapses[col.getAttribute('id')] = false;
                }
            }
        });
        this.eventListeners.push(
            addLiveEvent(
                '.collapse',
                'click',
                (event) => {
                    const collapseAble =
                        event.target.parentNode.getAttribute('id');
                    if (collapseAble != null) {
                        const expanded =
                            event.target.parentNode.getAttribute(
                                'aria-expanded'
                            );
                        this.expandedCollapses[collapseAble] =
                            expanded === 'true';
                    }
                },
                container
            )
        );

        this.setState = (newState) => {
            const oldState = this.state;
            this.state = {
                ...this.state,
                ...newState,
            };
            this.onStateChange(oldState, this.state);
            return this.state;
        };
        this.facet = container.querySelector('.productsearch-facet');
        this.form = null;
        if (this.facet != null) {
            this.form = this.facet.querySelector('form');
            this.facetBui = new Bui({
                container: this.facet,
                collapseStatus: this.expandedCollapses,
            });
        }
        this.result = container.querySelector('.productsearch-result');
        if (this.result != null) {
            this.headline = this.result.querySelector(
                '.productsearch-result__hl'
            );
            this.tagBar = this.result.querySelector(
                '.productsearch-result__tag-bar'
            );
            this.pagination = this.result.querySelector(
                '.productsearch-result__pagination'
            );
            this.products = this.result.querySelector(
                '.productsearch-result__products'
            );
            this.sortButton = this.result.querySelector('[name="sort"]');

            this.eventListeners.push(
                addEvent(this.sortButton, 'change', () =>
                    this.setState({sort: getFieldValue(this.sortButton)})
                )
            );
        }
        this.eventListeners.push(
            addLiveEvent('.s-pagination__list a', 'click', function () {
                window.scroller.top(0);
            })
        );
        this.initializeFacets();

        // Carsliders
        if (this.products != null) {
            this.carsliders = [
                ...this.products.querySelectorAll('.carslider'),
            ].map((slider) => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: false,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'vertical',
                    threeSixty: has360 ? true : false,
                });
            });
        }

        // if mobile version make the facet search collapsable
        this.watcher = window.dimensionWatcher.addWatch({
            namespace: 'productsearch',
            matchCondition([innerWidth, innerHeight]) {
                return innerWidth < 1024;
            },
            onappear() {
                self.setState({
                    filterCollapse: true,
                });
            },
            ondisappear() {
                self.setState({
                    filterCollapse: false,
                });
            },
        });

        this.update({container});
        this.eventListeners.push(
            addLiveEvent(
                '.productsearch-result__tag',
                'click',
                handleTag,
                container
            )
        );
        this.eventListeners.push(
            addLiveEvent(
                '.productsearch-result__remove-tag',
                'click',
                function () {
                    self.formInputs.forEach((element) => {
                        if (element.name !== 'budget')
                            resetFieldValue(element, true);
                    });
                    self.formInputs[0].dispatchEvent(new Event('change'));
                },
                container
            )
        );
    };
    this.initializeFacets = () => {
        console.log('initializeFacets');
        const params = queryString.parse(location.search);
        if (this.form != null) {
            this.formInputs = this.form.querySelectorAll('[name]');
            this.formInputs.forEach((element) => {
                if (!element.getAttribute('eventListenerAttached')) {
                    if (element.name === 'financingMonthlyRate[]') {
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                this.form.querySelector(
                                    '[name="budget"][value="salesprice[]"]'
                                ).checked = 'checked';
                            })
                        );
                        // reset range slider if no param has been found in the url
                        if (!params['financingMonthlyRate']) {
                            element.value = '';
                        }
                    }
                    if (element.name === 'salesprice[]') {
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                this.form.querySelector(
                                    '[name="budget"][value="financingMonthlyRate[]"]'
                                ).checked = 'checked';
                            })
                        );
                    }
                    if (element.name === 'fuelType') {
                        // console.log('initializeFacets: element.name=' + element.name + ', element.value=' + element.value);
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                if (element.value !== 'hybrid-petrol'
                                    && element.value !== 'hybrid-diesel'
                                    && element.value !== 'electric') {
                                    var subelements = this.form.querySelectorAll('[name="wltpRange[]"]');
                                    subelements.forEach((subelement) => {
                                        // console.log('initializeFacets: subelement.name=' + subelement.name + ', subelement.value=' + subelement.value);
                                        subelement.value = '';
                                    });
                                }
                            }));
                    }
                    if (element.name === 'wltpRange[]') {
                        // console.log('initializeFacets: element.name=' + element.name + ', element.value=' + element.value);
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                var superelement = this.form.querySelector('[name="fuelType"]');
                                if (superelement.value !== 'hybrid-petrol'
                                    && superelement.value !== 'hybrid-diesel'
                                    && superelement.value !== 'electric') {
                                    element.value = '';
                                    // console.log('initializeFacets: superelement.name=' + superelement.name + ', superelement.value=' + superelement.value);
                                }
                            })
                        );
                    }
                    if (element.name === 'color') {
                        this.eventListeners.push(
                            addEvent(element, 'click', () => {
                                let colorRadios =
                                    this.form.querySelectorAll(
                                        '[name="color"]'
                                    );
                                colorRadios.forEach((radio) => {
                                    if (
                                        radio.getAttribute('value') ===
                                        element.getAttribute('value')
                                    ) {
                                        radio.setAttribute(
                                            'checked',
                                            'checked'
                                        );
                                    } else {
                                        radio.removeAttribute('checked');
                                    }
                                });
                            })
                        );
                    }
                    if (element.name === 'previousOwner') {
                        // reset range slider if no param has been found in the url
                        if (!params['previousOwner']) {
                            element.value = '';
                        }
                    }
                    if (element.name === 'cat_brands') {
                        // reset range slider if no param has been found in the url
                        this.eventListeners.push(
                            addEvent(element, 'change', () => {
                                let modelSelection = this.form.querySelector(
                                    '[name="cat_models"]'
                                );
                                modelSelection.options.forEach((option) => {
                                    option.selected = false;
                                });
                            })
                        );
                    }
                    if (
                        element &&
                        element.getAttribute('name') &&
                        element.getAttribute('name').startsWith('facet-config_')
                    ) {
                        this.eventListeners.push(
                            addEvent(element, 'click', () => {
                                this.handleConfigFacetClick(element);
                            })
                        );
                    }

                    this.eventListeners.push(
                        addEvent(element, 'change', () => {
                            if (
                                self.state.filterCollapse &&
                                self.filterCollapse
                            ) {
                                self.filterCollapse.close();
                            } else {
                                // only scroll to top on NOT mobile devices
                                window.scrollTo({top: 0, behavior: 'smooth'});
                            }
                            this.pushData();
                        })
                    );

                    element.setAttribute('eventListenerAttached', true);
                }
            });
        }
    };
    this.onStateChange = (prevState) => {
        const {sort, filterCollapse} = this.state;
        if (prevState.sort !== sort) {
            this.pushData();
        }
        if (prevState.filterCollapse !== filterCollapse) {
            if (
                this.filterCollapse &&
                typeof this.filterCollapse.finalize === 'function'
            ) {
                this.filterCollapse.finalize();
                this.facet.classList.remove('mod--collapseable');
            }
            if (filterCollapse) {
                this.facet.classList.add('mod--collapseable');
                this.filterCollapse = new Collapse(this.facet, {
                    switcher: '.productsearch-facet__hl',
                    flyout: '.productsearch-facet__wrapper',
                    content: '.productsearch-facet__form',
                });
            }
        }
    };
    this.handleConfigFacetClick = (element) => {
        const configFacetUrl =
            '?' +
            element.getAttribute('data-facet-name') +
            '=' +
            element.getAttribute('data-facet-value');
        history.push({
            pathname: this.form.getAttribute('action'),
            search: configFacetUrl,
        });
    };
    this.pushData = () => {
        const exclude = Array.from(
            this.form.querySelectorAll('[name="budget"]')
        ).reduce((collection, current) => {
            const value = getFieldValue(current);
            if (value) {
                collection.push(value);
            }
            return collection;
        }, []);
        exclude.push('budget');
        const data = getFormData(this.form, {exclude});
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('searchalert')) {
            data.searchalert = urlParams.get('searchalert');
        }
        joinQueryOptions(data);
        if (this.state.sort) {
            data.sort = this.state.sort;
        }
        history.push({
            pathname: this.form.getAttribute('action'),
            search: queryString.stringify(data),
        });

        window.dispatchEvent(new CustomEvent('customUrlChangeEvent'));

        // fire br pixel on facet change
        firePixelEvent();
    };
    const handleTag = function (e) {
        const key = this.getAttribute('data-filter-key');
        const filterElement = self.facet.querySelector('[name^="' + key + '"]');
        if (filterElement.name.endsWith('[]')) {
            const groupElements = self.facet.querySelectorAll(
                '[name^="' + key + '"]'
            );
            groupElements.forEach((element) => {
                resetFieldValue(element, true);
            });
        } else {
            resetFieldValue(filterElement, true);
        }
        if (filterElement.name == 'fuelType') {
            const groupElements = self.facet.querySelectorAll(
                '[name^="wltpRange"]'
            );
            groupElements.forEach((element) => {
                resetFieldValue(element, true);
            });
            resetFieldValue(filterElement, true);
        }
        filterElement.dispatchEvent(new Event('change'));
    };
    this.beforeUpdate = () => {
        // do stuff like finalizing all temporary components
        if (this.resultBui) {
            this.resultBui.finalize();
        }

        // TODO remove tag events
    };
    this.update = ({container}) => {
        const newProducts = container.querySelector(
                '.productsearch-result__products'
            ),
            newTagBar = container.querySelector(
                '.productsearch-result__tag-bar'
            ),
            newPagination = container.querySelector(
                '.productsearch-result__pagination'
            ),
            newHeadline = container.querySelector('.productsearch-result__hl');
        if (this.headline != null) {
            this.headline.replaceWith(newHeadline);
        }
        this.headline = newHeadline;
        if (this.tagBar != null) {
            this.tagBar.replaceWith(newTagBar);
        }
        this.tagBar = newTagBar;
        if (this.pagination != null) {
            this.pagination.replaceWith(newPagination);
        }
        this.pagination = newPagination;
        if (this.products != null) {
            this.products.replaceWith(newProducts);
            this.products = newProducts;
            this.resultBui = new Bui({
                container: this.result,
                collapseStatus: this.expandedCollapses,
            });
            this.carsliders = [
                ...this.products.querySelectorAll('.carslider'),
            ].map((slider) => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: false,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'vertical',
                    threeSixty: has360 ? true : false,
                });
            });
        }

        const newFacet = container.querySelector('.productsearch-facet');
        if (newFacet != null) {
            this.facet.replaceWith(newFacet);
            this.facet = newFacet;

            this.form = this.facet.querySelector('form');
            this.facetBui.finalize();
            this.facetBui = new Bui({
                container: this.facet,
                collapseStatus: this.expandedCollapses,
            });
            this.initializeFacets();
        }
    };
    this.handleAISearchVisibility = () => {
        const aiSearchOpenerBanner = document.querySelector('.aisearch__product-search-banner');
        const widgetVisibility = isVisible();
        if (aiSearchOpenerBanner) {
            if (widgetVisibility) {
                aiSearchOpenerBanner.style.display = 'block';
            } else {
                aiSearchOpenerBanner.style.display = 'none';
            }
        }
    }
    this.finalize = () => {
        if (this.facetBui && typeof this.facetBui.finalize === 'function') {
            this.facetBui.finalize();
        }
        if (this.resultBui && typeof this.resultBui.finalize === 'function') {
            this.resultBui.finalize();
        }
        if (this.watcher) {
            window.dimensionWatcher.removeWatch(this.watcher);
        }
        if (this.eventListeners.length) {
            this.eventListeners.forEach((event) => event.remove());
        }
        if (this.carsliders) {
            this.carsliders.forEach((slider) => slider.finalize());
        }
    };
    this.initialize();
}

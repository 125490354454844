import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

(function() {
    var origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function() {
        NProgress.start();

        this.addEventListener('load', function() {
            NProgress.done();
        });
        origOpen.apply(this, arguments);
    };
})();

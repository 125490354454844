import Bui from '../../assets/js/Bui';

export default function Productsearch({ container, history }) {
  const self = this;

  this.initialize = () => {
    this.accordion = this.accordion = new Bui({
      container: container,
    });
  };

  this.finalize = () => {
    if (this.accordion && typeof this.accordion.finalize === 'function') {
      this.accordion.finalize();
    }
  };
  this.initialize();
}

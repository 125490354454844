const PIXEL_INFO_CONTAINER = "brPixelInformation";
const exclude = ["/checkout/intro", "/checkout/shipment", "/checkout/data", "/checkout/payment", "/cockpit", "/registrierung"];

export default function firePixelEvent() {
    const cookiebot = window.Cookiebot;
    if (cookiebot) {
        fire();
    } else {
        setTimeout(function () {
            // console.log("Cookie consent was undefined. Try again.");
            fire();
        }, 1000);
    }
}

const fire = () => {
    const cookiebot = window.Cookiebot;
    if (cookiebot && cookiebot.consent && cookiebot.consent && cookiebot.consent.preferences) {
        window.br_data = {};
        br_data.acct_id = "6348";
        br_data.ptype = "other";
        br_data.domain_key = "beresa_de_de";
        //br_data.user_id = "eine anonyme userid";

        const specificData = getSpecificData();
        mapData(specificData, br_data);

        let pathname = window.location.pathname;
        pathname = pathname.replace("/site", "");
        if (!exclude.includes(pathname)) {
            (() => {
                /* Pixel brtrk container for local testing
                var brtrk = document.createElement('script');
                brtrk.type = 'text/javascript';
                brtrk.async = true;
                //brtrk.src = ;
                brtrk.text = "(function() {console.log('Pixel Event gefeuert! ', window.br_data);})();"; */
                //console.log("br_data: ", br_data);

                var brtrk = document.createElement('script');
                brtrk.type = 'text/javascript';
                brtrk.async = true;
                brtrk.src = "//cdns.brsrvr.com/v1/br-trk-6348.js";

                let scriptContainerId = "pixelScript";
                let scriptContainer = document.getElementById(scriptContainerId);
                if (scriptContainer) {
                    scriptContainer.remove();
                }

                let div = document.createElement('div');
                div.setAttribute("id", scriptContainerId);
                div.appendChild(brtrk);

                let s = document.getElementsByTagName('head')[0];
                s.appendChild(div);
            })();
        }
    } else {
        // console.log("Can not fire pixel cause cookie consent 'preferences' is missing.");
    }
};

const getSpecificData = () => {
    var data = {};

    const pathname = window.location.pathname;

    const homePage = /\/$|\/site\/$/;
    const carDetailPage = /\/fahrzeuge\/[a-zA-Z\-&.%\*0-9_]*.html/;
    const carSearchPage = /\/fahrzeuge$|\/fahrzeuge[a-zA-Z\-&.%?=:0-9_]+/;
    const shopSearchPage = /\/shop$|\/shop[a-zA-Z\-&.%?=:0-9_]+/;
    const checkoutFinished = /\/checkout\/success$/;

    if (homePage.test(pathname)) {
        data.ptype = "homepage";
    } else if (carDetailPage.test(pathname)) {
        data.ptype = "product";

        const specificInfos = selectPixelInfoContainer(data.ptype);
        if (specificInfos) {
            data.ptype = specificInfos.getAttribute("data-ptype");
            data.prod_id = specificInfos.getAttribute("data-prod_id");
            data.prod_name = specificInfos.getAttribute("data-prod_name");
            data.sku = specificInfos.getAttribute("data-sku");
        }
    } else if (carSearchPage.test(pathname) || shopSearchPage.test(pathname)) {
        data.ptype = "search";

        const queryParams = window.location.search;
        data.search_term = queryParams;

        const specificInfos = selectPixelInfoContainer(data.ptype);
        if (specificInfos) {
            data.ptype = specificInfos.getAttribute("data-ptype");
        }
    } else if (checkoutFinished.test(pathname)) {
        data.ptype = "other";

        const specificInfos = selectPixelInfoContainer(data.ptype);
        if (specificInfos) {
            const lineItemContainers = specificInfos.querySelectorAll('.checkout-success__pixel-line-item');
            let lineItems = [];
            lineItemContainers.forEach(container => {
                lineItems.push({
                    "prod_id": container.getAttribute("data-prod_id"),
                    "sku": container.getAttribute("data-sku"),
                    "name": container.getAttribute("data-name"),
                    "quantity": container.getAttribute("data-quantity"),
                    "price": container.getAttribute("data-price")
                });
            });
            data.basket = {"items": lineItems};
            data.order_id = specificInfos.getAttribute("data-order_id");
            data.basket_value = specificInfos.getAttribute("data-basket_value");
            data.is_conversion = 1;
        }
    } else if (!exclude.includes(pathname)) {
        data.ptype = "other";

        const specificInfos = selectPixelInfoContainer(data.ptype);
        if (specificInfos) {
            data.ptype = specificInfos.getAttribute("data-ptype");
        }
    }

    return data;
};

const selectPixelInfoContainer = (ptype) => {
    const pixelInfoContainers = document.querySelectorAll('#' + PIXEL_INFO_CONTAINER);

    let specificContainer = pixelInfoContainers[0];
    if (pixelInfoContainers.length > 1) {
        pixelInfoContainers.forEach(container => {
            if (ptype === container.getAttribute("data-ptype")) {
                specificContainer = container;
            }
        });
    }

    return specificContainer;
};

const mapData = (from, to) => {
    if (from.ptype) {
        to.ptype = from.ptype;
    }
    if (from.prod_id) {
        to.prod_id = from.prod_id;
    }
    if (from.prod_name) {
        to.prod_name = from.prod_name;
    }
    if (from.sku) {
        to.sku = from.sku;
    }
    if (from.item_id) {
        to.item_id = from.item_id;
    }
    if (from.item_name) {
        to.item_name = from.item_name;
    }
    if (from.basket_value) {
        to.basket_value = from.basket_value;
    }
    if (from.order_id) {
        to.order_id = from.order_id;
    }
    if (from.basket) {
        to.basket = from.basket;
    }
    if (from.is_conversion) {
        to.is_conversion = from.is_conversion;
    }
    if (from.search_term) {
        let searchTerm = "";
        if (from.search_term.includes("cat_models")) {
            searchTerm = removeURLParameter(from.search_term, "cat_brands");
            searchTerm = searchTerm.replace("cat_models", "category");
        } else {
            searchTerm = from.search_term.replace("cat_brands", "category");
        }
        searchTerm = searchTerm.replace("salesprice", "sku_grossPrice");
        searchTerm = searchTerm.replace("dieselParticulateFilter", "dieselParticulateFilter");
        searchTerm = searchTerm.replace("offerno", "pid");
        searchTerm = searchTerm.replace("type", "vehicleCategoryID");
        searchTerm = searchTerm.replace("numberofdoors", "doors");
        searchTerm = searchTerm.replace("color", "colors");
        searchTerm = searchTerm.replace("registration", "firstRegistrationDateSortable");
        searchTerm = searchTerm.replace("cubiccapacity", "cubicCapacity");
        searchTerm = searchTerm.replace("airconditioning", "ac");
        searchTerm = searchTerm.replace("manufacturerseal", "qualitySeal");
        searchTerm = searchTerm.replace("vehicletype", "type");
        searchTerm = searchTerm.replace("state", "condition");
        searchTerm = searchTerm.replace("previousOwner", "previousOwners");
        searchTerm = searchTerm.replace("consumption", "consumptionCombined");
        searchTerm = searchTerm.replace("euronorm", "emissionClass");
        searchTerm = searchTerm.replace("emissionsticker", "emissionSticker");
        searchTerm = searchTerm.replace("numberofseats", "numberOfSeats");

        to.search_term = searchTerm;
    }
};


function removeURLParameter(url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        for (var i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

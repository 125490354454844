import {addEvent,} from 'buikit/src/assets/js/utils/eventHandling';
import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";
import {isVisible} from "../../assets/js/utils/AISearchVisibilityController";

export default function SearchAlertBanner({container, history, config}) {
    this.toFinalize = [];
    const parser = new DOMParser();
    this.initialize = () => {
        this.container = container;
        this.eventListeners = [];
        this.initBanner();
        this.initConfigForm();
        this.handleAiSearchBannerVisibility();
    };
    this.handleAiSearchBannerVisibility = () => {
        const searchAlertBanner = window.document.querySelector('.aisearch__product-search-banner');
        if (searchAlertBanner) {
            const visibility = isVisible();
            if (visibility) {
                searchAlertBanner.style.display = 'block';
            } else {
                searchAlertBanner.style.display = 'none';
            }
        }
    }
    this.replaceHrefForBannerContainer = (element) => {
        if (element) {
            let url = element.getAttribute("data-href");
            const params = new URLSearchParams(window.location.search);
            if (params) {
                url += "?" + params.toString();
            }
            element.setAttribute('href', url)
        }
    }
    this.initBanner = () => {
        this.bannerContainer = this.container.querySelector('.search-alert-banner__document-container');
        this.editButton = this.container.querySelector('.search-alert-banner__input-button');
        this.eventListeners.push(
            addEvent(window, 'customUrlChangeEvent', (event) => {
                this.replaceHrefForBannerContainer(this.bannerContainer);
                this.replaceHrefForBannerContainer(this.editButton);
            }, false)
        );

        this.replaceHrefForBannerContainer(this.editButton);
        this.replaceHrefForBannerContainer(this.bannerContainer);
    }
    this.initConfigForm = () => {
        this.form = this.container.querySelector('.search-alert-config__action-form');
        this.saveButton = this.container.querySelector('.search-alert-config__input-button-save');
        this.addButton = this.container.querySelector('.search-alert-config__input-button-add');

        this.backToSearchPageButton = this.container.querySelector('.search-alert-config__back-to-search-button');
        this.eventListeners.push(
            addEvent(window, 'customUrlChangeEvent', (event) => {
                this.replaceHrefForBannerContainer(this.backToSearchPageButton);
            }, false)
        );
        this.replaceHrefForBannerContainer(this.backToSearchPageButton);


        if (this.form) {
            if (this.saveButton) {
                this.eventListeners.push(
                    addEvent(
                        this.form.querySelector('.search-alert-config__input-button-save'),
                        'click',
                        (e) => {
                            e.preventDefault();
                            this.performeRestCall("update");
                        }
                    )
                );
            }

            if (this.addButton) {
                this.eventListeners.push(
                    addEvent(
                        this.form.querySelector('.search-alert-config__input-button-add'),
                        'click',
                        (e) => {
                            e.preventDefault();
                            this.performeRestCall("add");
                        }
                    )
                );
            }
        }
    }
    this.performeRestCall = (action) => {
        const namespace = this.form.getAttribute("data-namespace");
        const dataContainer = this.form.querySelector('.search-alert-config__data-container');
        const inputField = this.form.querySelector("input");
        let title = "";
        if (inputField) {
            title = inputField.value;
        }

        let actionUrl = this.form.action;
        actionUrl = removeURLParameter(actionUrl, "searchalert");
        if (action === "update") {
            actionUrl += "&searchalert=" + dataContainer.getAttribute("data-alert-id");
        }

        let facets = new URLSearchParams(window.location.search);
        facets.delete("searchalert");

        const params = {
            [`${namespace}:title`]: title,
            [`${namespace}:action`]: action,
            [`${namespace}:facets`]: facets.toString()
        };
        fetchUrl({
            params,
            url: actionUrl,
            method: this.form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    const dom = parser.parseFromString(response.data, 'text/html');
                    const newForm = dom.querySelector('.search-alert-config__action-form');
                    const dataContainer = newForm.querySelector('.search-alert-config__data-container');
                    const alertId = dataContainer.getAttribute("data-alert-id");

                    let url = new URL(window.location.href);
                    url.searchParams.set("searchalert", alertId);
                    history.push(url.href.slice(url.origin.length));

                    this.form.innerHTML = newForm.innerHTML;
                    this.initConfigForm();
                }
            );
    }
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(event => event.remove());
        }
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
    };

    function removeURLParameter(url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    this.initialize();
}

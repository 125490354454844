import Carslider from '../carslider';
import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";

// Du kannst in jeder Komponente den store { container, ...., store } ergänzen und damit definieren. Du kannst dann überall in der Komponente darauf zugreifen
export default function Favorites({container, history, config, store}) {
    this.toFinalize = [];
    const self = this;
    const parser = new DOMParser();
    this.initialize = () => {
        this.container = container;
        // store.subscribe registriert eine Methode, die immer dann aufgerufen wird, wenn sich ein Wert im Store ändert. Z.B. die Favoriten.
        this.unsubscribeFromStore = store.subscribe(this.handleBookmarkChange);
        this.hl = container.querySelector('.productsearch-result__hl');
        this.resultContainer = container.querySelector('.productsearch-result');
        if (this.resultContainer) {
            this.renderingUrl = this.resultContainer.getAttribute("data-rendering-url");
        }
        // store.dispatch(clearBookmark()) kannst du jetzt in jeder Komponente aufrufen, in der du den Store definiert hast. Diese Methode löscht alle Favoriten aus dem Store --> Count geht auf 0
        // ACHTUNG! Wenn store.dispatch aufgerufen wird kann es sein, dass Komponenten neu initialisiert werden. Deshalb store.dispatch besser nicht in einer initialize Methode aufrufen, da es sonst
        // zu Endlosschleifen store.dispatch -> initialize -> store.dispatch... kommen kann. Am besten in einen onClick Listener o.ä.
        // store.dispatch(clearBookmark());
        // Mit store.getState() kannst du auf den Store zugreifen um dir Daten daraus zu ziehen. Z.B. alle Favoriten. Geht ebenfalls sobald du den Store definiert hast.
        // console.log(store.getState());

        // Initialize all carsliders
        this.carsliders = [...container.querySelectorAll('.carslider')].map(
            slider => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: true,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'horizontal',
                    threeSixty: has360 ? true : false,
                });
            }
        );
    };
    // Wird aufgerufen, wenn sich ein Wert im Redux Store ändert --> Es wird auf Veränderungen der Favoriten reagiert.
    this.handleBookmarkChange = () => {
        const {bookmarks} = store.getState() || {};
        const carCards = [
            ...container.querySelectorAll('.bookmark-list [data-car-card-id]'),
        ];

        carCards.forEach(c => {
            const id = c.getAttribute('data-car-card-id');
            if (!bookmarks.includes(id)) {
                c.remove();
            }
        });

        const params = {};
        fetchUrl({
            params,
            url: this.renderingUrl,
            method: "POST",
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then(
            response => {
                const dom = parser.parseFromString(response.data, 'text/html');
                const newTitle = dom.querySelector(".productsearch-result__hl");
                self.title = self.container.querySelector(".productsearch-result__hl");
                self.title.innerHTML = newTitle.innerHTML;

                if (bookmarks.length < 1) {
                    const newResultPlaceholder = dom.querySelector(".productsearch-result__products");
                    self.result = self.container.querySelector(".productsearch-result__products");
                    self.result.innerHTML = newResultPlaceholder.innerHTML;
                }
            }
        );
    };
    this.finalize = () => {
        if (this.unsubscribeFromStore) {
            this.unsubscribeFromStore();
        }

        if (this.carsliders) {
            this.carsliders.forEach(slider => slider.finalize());
        }
    };
    this.initialize();
}

const smallerBetter = ['left', 'top'],
    biggerBetter = ['right', 'bottom'];

export default function RectHighlighter(elems, options) {
    if (!elems.length) {
        return;
    }
    const outerBox = elems.reduce((acc, el) => {
        const box = el.getBoundingClientRect();
        const currentBox = { ...acc };
        smallerBetter.forEach(prop => {
            if (!currentBox[prop] || box[prop] < currentBox[prop]) {
                currentBox[prop] = box[prop];
            }
        });
        biggerBetter.forEach(prop => {
            if (!currentBox[prop] || box[prop] > currentBox[prop]) {
                currentBox[prop] = box[prop];
            }
        });
        return currentBox;
    }, {});
    const highlight = document.createElement('div');
    if (options.offClass) {
        highlight.setAttribute('class', options.offClass);
    }
    highlight.style.cssText = `
        position: fixed;
        left: ${outerBox.left}px;
        top: ${outerBox.top}px;
        width: ${outerBox.right - outerBox.left}px;
        height: ${outerBox.bottom - outerBox.top}px;
    `;
    document.body.appendChild(highlight);
    if (options.onClass) {
        setTimeout(() => {
            highlight.classList.add(options.onClass);
        });
    }

    // if ('onwheel' in document.documentElement) {
    //     this.eventListeners.push(
    //         addEvent(options.container, 'wheel', function() {
    //             self.clear();
    //         })
    //     );
    // }
    // if ('ontouchstart' in document.documentElement) {
    //     this.eventListeners.push(
    //         addEvent(options.container, 'touchstart', function() {
    //             self.clear();
    //         })
    //     );
    // }
    setTimeout(() => {
        if (options.onClass) {
            highlight.classList.remove(options.onClass);
        }
        setTimeout(() => {
            highlight.parentNode.removeChild(highlight);
        }, options.transition || 1000);
    }, options.delay || 3000);
}

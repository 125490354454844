import {tns} from 'tiny-slider/src/tiny-slider';

export default function Equipmentslider(element, options) {
    this.toFinalize = [];
    this.initialize = () => {
        const self = this;
        this.wrapper = element.querySelector('.carslider__wrapper');
        this.controls = element.querySelector('.carslider__controls');

        this.images = element.querySelector('.carslider__images');
        this.imagesLength = 0;

        this.thumbnails = element.querySelector('.carslider__thumbnails');
        this.thumbnailsLength = 0;


        if (this.images) {
            this.imagesLength = Array.from(
                this.images.querySelectorAll('.carslider__image')
            ).length;
        }

        if (this.thumbnails) {
            this.thumbnailsLength = Array.from(
                this.thumbnails.querySelectorAll('.carslider__thumbnail')
            ).length;
        }

        if (this.thumbnailsLength > 0 && options.nav) {
            this.thumbSlider = new tns({
                container: this.thumbnails,
                axis: options.thumbAxis ? options.thumbAxis : 'horizontal',
                items: 3,
                gutter: 20,
                slideBy: 1,
                autoplay: false,
                mouseDrag: options.mouseDrag,
                loop: false,
                nav: false,
                controls: false,
                lazyload: true,
                responsive: {
                    769: {items: options.thumbItems},
                },
            });
        }

        if (this.imagesLength > 0) {
            this.slider = new tns({
                container: this.images,
                controls: false,
                controlsContainer: this.controls,
                controlsPosition: 'bottom',
                nav: options.nav,
                navContainer: this.thumbnails ? this.thumbnails : false,
                navAsThumbnails: this.thumbnails ? true : false,
                navPosition: this.thumbnails ? 'bottom' : false,
                slideBy: 'page',
                items: 1,
                mouseDrag: options.mouseDrag,
                loop: false,
                lazyload: true,
                startIndex: options.threeSixty ? 1 : 0,
            });
        }

        if (this.slider && this.imagesLength > 0) {
            this.slider.events.on('indexChanged', (info, eventName) => {
                if (this.thumbSlider) {
                    this.thumbSlider.goTo(info.index);
                }
            });
        }
    };

    this.finalize = () => {
        if (this.slider) {
            this.slider.destroy();
        }

        if (this.thumbSlider) {
            this.thumbSlider.destroy();
        }
    };

    this.initialize();
}

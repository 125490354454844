import {tns} from 'tiny-slider/src/tiny-slider';

export default function GeneralSlider(container) {
    this.toFinalize = [];

    this.setPointerStart = (x, y) => {
        this.pointerStart = {x, y};
    };

    this.getPointerStart = () => {
        return this.pointerStart;
    };

    this.dragstartFn = function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
    };

    this.pointerDownFn = function (event) {
        if (event.target !== this.wrapper) {
            this.setPointerStart(event.clientX, event.clientY);
        }
    };

    this.clickFn = function (event) {
        let pointerStartX = this.getPointerStart().x;
        if (Math.abs(pointerStartX - event.clientX) > 10) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    };

    this.initialize = () => {
        this.wrapper = container.querySelector('.swiper-wrapper');

        this.wrapper.addEventListener('dragstart', this.dragstartFn);
        this.wrapper.addEventListener('pointerdown', this.pointerDownFn.bind(this));
        this.wrapper.addEventListener('click', this.clickFn.bind(this));

        const items = container.querySelectorAll('.swiper-slide');
        const itemCount = items && items.length < 3 ? items.length : 3;

        this.swiper = new tns({
            container: this.wrapper,
            items: 1,
            slideBy: 'page',
            mouseDrag: true,
            preventActionWhenRunning: true,
            controls: true,
            nextButton: container.querySelector('.swiper-button-next'),
            prevButton: container.querySelector('.swiper-button-prev'),
            nav: false,
            navPosition: 'bottom',
            loop: false,
            lazyload: true,
            responsive: {
                768: {
                    items: 2,
                },
                1180: {
                    items: itemCount,
                },
            },
        });

        this.adjustCardHeights();
    };

    this.adjustCardHeights = () => {
        this.cards = container.querySelectorAll('.swiper-slide');
        this.cards = this.cards || [];

        let highestCardsHeight = 0;

        const measureSize = (card) => {
            let currCardHeight = card.getBoundingClientRect().height;
            highestCardsHeight = (currCardHeight > highestCardsHeight) ? currCardHeight : highestCardsHeight;
        };

        this.cards.forEach(measureSize);

        this.cards.forEach((card) => {
            card.style.height = highestCardsHeight + "px";
        });
    };

    this.finalize = () => {
        this.wrapper.removeEventListener("dragstart", this.dragstartFn);
        this.wrapper.removeEventListener("pointerdown", this.pointerDownFn);
        this.wrapper.removeEventListener("click", this.clickFn);

        if (this.swiper) {
            this.swiper.destroy();
        }
    };
}

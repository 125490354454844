import config from './config.js';
import queryString from 'query-string';
import {pushMarkup, pushUrl} from './actions';
import {addLiveEvent} from 'buikit/src/assets/js/utils/eventHandling';
import Accordion from '../../br-components/accordion';
import Divider from '../../br-components/divider';
import Infobox from '../../br-components/infobox';
import Checkout from '../../br-components/checkout';
import Contentsimple from '../../br-components/contentsimple';
import Contactsearch from '../../br-components/contactsearch';
import Contentteaser from '../../br-components/contentteaser';
import Countup from '../../br-components/countup';
import Countdown from '../../br-components/countdown';
import GlobalSearchBar from '../../br-components/globalsearchbar';
import Leasing from '../../br-components/leasing';
import Opener from '../../br-components/opener';
import HeaderCart from '../../br-components/header/headerCart';
import Iframe from '../../br-components/iframe';
import Newshub from '../../br-components/newshub';
import Saleshub from '../../br-components/saleshub';
import SaleshubReduced from '../../br-components/saleshub-reduced';
import NewshubReduced from '../../br-components/newshub-reduced';
import Jobshub from '../../br-components/jobshub';
import JobshubReduced from '../../br-components/jobshub-reduced';
import JobshubLayoutTwo from '../../br-components/jobshub-layout-two';
import JobDetail from '../../br-components/jobdetail';
import Productdetail from '../../br-components/productdetail';
import Equipmentdetail from '../../br-components/equipmentdetail';
import Productsearch from '../../br-components/productsearch';
import Flexibleproductlist from '../../br-components/flexibleproductlist';
import Productstream from '../../br-components/productstream';
import Favorites from '../../br-components/favoritesList';
import ShoppingCart from '../../br-components/shoppingcart';
import SearchAlertBanner from '../../br-components/searchalertbanner';
import Logo from './microControllers/LogoController';
import AddToCardModal from '../../br-components/addtocardmodal';
import PageTitle from './microControllers/PageTitleController';
import Bookmarks from './microControllers/BookmarkController';
import GoogleMaps from './microControllers/GoogleMapsController';
import ShoppingCartElement from './microControllers/ShoppingCartElementController';
// import Googlemap from '../../br-components/googlemap';
import Timeline from '../../br-components/timeline';
import ChangeUserRoles from '../../br-components/roles/change-user-roles';
import firePixelEvent from './utils/BrPixel';
import Stylevalue from '../../br-components/stylevalue';
import FormattedTable from './microControllers/FormattedTableController';
import CockpitProfile from '../../br-components/cockpitprofile';
import CockpitCars from '../../br-components/cockpitcars';
import CockpitTyres from '../../br-components/cockpittyres';
import CockpitAlerts from '../../br-components/cockpitalerts';
import Nextbestactions from '../../br-components/nextbestactions';
import CockpitRegistration from '../../br-components/cockpit/registration';
import B2B from '../../br-components/b2b';
import ServiceBooking from '../../br-components/servicebooking';
import SantanderWidget from "../../br-components/santander-widget";
import AISearchWidget from "./microControllers/AISearchWidgetController";

const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();
globalThis.controllerHistory = history;

globalThis.aiSearchWidgetConfig = {
    configInternal: {},
    listener: [],
    get config() {
        return this.configInternal;
    },
    set config(config) {
        this.configInternal = config;
        this.listener.forEach(listener => listener(config, this.configInternal));
    },
    addListener: function (listener) {
        this.listener.push(listener);
    },
};

const ComponentClasses = {
    Favorites,
    Accordion,
    Infobox,
    Checkout,
    Contentsimple,
    CockpitProfile,
    CockpitCars,
    CockpitTyres,
    CockpitAlerts,
    Divider,
    CockpitRegistration,
    Contactsearch,
    Contentteaser,
    Countup,
    Countdown,
    GlobalSearchBar,
    HeaderCart,
    // Googlemap,
    Leasing,
    Opener,
    Newshub,
    SearchAlertBanner,
    Saleshub,
    Jobshub,
    JobDetail,
    JobshubReduced,
    JobshubLayoutTwo,
    SaleshubReduced,
    NewshubReduced,
    Productdetail,
    Equipmentdetail,
    Productsearch,
    Productstream,
    Flexibleproductlist,
    ShoppingCart,
    Timeline,
    Stylevalue,
    Iframe,
    AddToCardModal,
    ChangeUserRoles,
    Nextbestactions,
    B2B,
    // ZipToPage deactivated cause of redirect problems
    ServiceBooking,
    SantanderWidget,
};

const microControllers = [
    Logo,
    PageTitle,
    Bookmarks,
    FormattedTable,
    ShoppingCartElement,
    GoogleMaps,
    AISearchWidget,
];

function MicroController({html, store}) {
    this.initialize = () => {
        this.controllers = microControllers.map((C) => new C({html, store}));
    };
    this.update = ({html}) => {
        this.controllers.forEach((c) => c.update && c.update({html}));
    };
    this.onPageReady = ({html}) => {
        this.controllers.forEach(
            (c) => c.onPageChange && c.onPageChange({html})
        );
    };
    this.initialize();
}

function Controller({store}) {
    this.store = {
        bookmarks: [],
        shoppingCartElements: {},
        location: {},
        components: [],
        html: {},
    };
    this.eventListeners = [];
    this.isInitial = true;

    const initialize = () => {
        this.unsubscribeFromStore = store.subscribe(this.handleStore);
        this.unlistenHistory = history.listen(this.handleLocationChange);

        // prevent reload on link click
        this.eventListeners.push(
            addLiveEvent('a', 'click', function (e) {
                const {origin} = window.location;
                const href = this.getAttribute('href');
                if (href[0] === '/' || href.indexOf(origin) === 0) {
                    const target = e.target.getAttribute('target');
                    if (target === '_blank' || target === '_new') {
                        return;
                    }
                    e.preventDefault();
                    history.push(this.href.slice(this.origin.length));
                } else if (href[0] === '#') {
                    const elem = document.getElementById(href.slice(1));
                    if (elem && window.scroll) {
                        e.preventDefault();
                        const position = elem.getBoundingClientRect();
                        window.scroll({
                            top: position.top + window.pageYOffset - 80,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }
                }
            })
        );

        // inital push
        history.replace(
            window.location.href.slice(window.location.origin.length)
        );

        // fire pixel event on initial page load
        firePixelEvent();
    };
    this.handleStore = () => {
        const {location, html} = store.getState() || {};

        if (!this.microController) {
            this.microController = new MicroController({html, store});
        }

        const params = new URLSearchParams(window.location.search);
        if (params.has('hard-reload') && params.get('hard-reload') === 'true') {
            // console.log('do hard reload');
            window.location.href = removeURLParameter(
                window.location.toString(),
                'hard-reload'
            );
        }

        // if pathname changed exchange whole site
        if (
            location.pathname !== this.store.location.pathname ||
            typeof this.store.location.pathname === 'undefined'
        ) {
            document.getElementById('globalsearchinputfield').value = '';
            document.scrollingElement.scrollTop = 0;
            if (html.rawBody !== this.store.html.rawBody && html.body) {
                this.removeComponents();
                document
                    .getElementById('content')
                    .replaceWith(html.content.cloneNode(true));

                this.microController.update({
                    html,
                });
                this.detectComponents();
                this.store.location = location;

                // Fire Google Tag Manager Event when HTML is replaced and
                // the DOM is ready.
                if (
                    document.readyState === 'complete' ||
                    document.readyState === 'interactive'
                ) {
                    this.microController.onPageReady({
                        html,
                    });
                    setTimeout(() => {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'htmlReplaced',
                        });

                        // fire pixel event on page change
                        firePixelEvent();
                    }, 10);
                }
            }
        } else if (html.rawBody !== this.store.html.rawBody && html.body) {
            // prepare components for update
            this.store.components.forEach((component) => {
                if (
                    component.handler &&
                    typeof component.handler.beforeUpdate === 'function'
                )
                    component.handler.beforeUpdate();
            });
            this.microController.update({
                html,
            });
            // update all loaded components
            const newComponents = html.body.querySelectorAll(
                config.componentSelector
            );
            this.store.components.forEach((component, index) => {
                if (
                    component.handler &&
                    typeof component.handler.update === 'function'
                )
                    component.handler.update({
                        container: newComponents[index],
                        store,
                    });
            });
            this.store.html = html;
        }
    };

    this.handleLocationChange = ({location}) => {
        const {pathname, search} = location;
        const config = {
            url: pathname,
            method: 'get',
            params: queryString.parse(search),
        };
        if (!this.isInitial) {
            store.dispatch(pushUrl(config));
        } else {
            store.dispatch(pushMarkup(config));
            this.isInitial = false;
        }

        // remove noscroll class which comes from santander widget
        let htmlTag = document.querySelector('html');
        if (htmlTag) {
            if (htmlTag.classList.contains("noscroll")) {
                htmlTag.classList.remove("noscroll");
            }
        }
    };

    // According to components on Page add specific class to documents body
    this.detectComponents = () => {
        const COMPONENT_CLASS_STARTS_WITH = 'contains-component';
        const classList = Array.from(document.body.classList);
        classList.forEach((maybeMatchingClass) => {
            if (maybeMatchingClass.startsWith(COMPONENT_CLASS_STARTS_WITH)) {
                document.body.classList.remove(maybeMatchingClass);
            }
        });

        document.querySelectorAll('div[data-bodyclass]').forEach((node) => {
            const maybeBodyClass = node.getAttribute('data-bodyclass');
            if (!!maybeBodyClass) {
                document.body.classList.add(maybeBodyClass);
            }
        });

        document
            .querySelectorAll(config.componentSelector)
            .forEach((component) => {
                // try, because JSON.parse can crash if somebody put in unparseable code
                try {
                    const componentConfig = JSON.parse(
                        component.getAttribute(config.componentIdentifier)
                    );
                    const componentObject = {
                        dom: component,
                    };
                    if (ComponentClasses[componentConfig.name]) {
                        componentObject.handler = new ComponentClasses[
                            componentConfig.name
                            ]({
                            container: component,
                            history,
                            config: componentConfig,
                            store,
                        });
                    }
                    this.store.components.push(componentObject);
                } finally {
                }
            });
    };

    this.removeComponents = () => {
        if (this.store.components.length) {
            this.store.components.forEach((component) => {
                if (
                    component.handler &&
                    typeof component.handler.finalize === 'function'
                ) {
                    component.handler.finalize();
                }
            });
        }
        this.store.components.length = 0;
    };

    this.finalize = () => {
        if (this.unsubscribeFromStore) this.unsubscribeFromStore();
        if (this.unlistenHistory) this.unlistenHistory();
        if (this.eventListeners.length)
            this.eventListeners.forEach((event) => event.remove());
    };
    initialize();
}

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

export default Controller;

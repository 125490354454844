import fetchUrl from 'buikit/src/assets/js/utils/fetchUrl';

// Action key that carries Server call info interpreted by this Redux middleware.
export const CALL_HTML = 'Call Server for HTML';

// A Redux middleware that interprets actions with CALL_HTML info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    const callHTML = action[CALL_HTML];
    if (typeof callHTML === 'undefined') {
        return next(action);
    }
    const { config, types, location } = callHTML;

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }

    const actionWith = data => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_HTML];
        return finalAction;
    };

    const [requestType, successType, failureType] = types;
    next(actionWith({ type: requestType }));

    return fetchUrl(config).then(
        response =>
            next(
                actionWith({
                    response,
                    type: successType,
                    location,
                })
            ),
        error =>
            next(
                actionWith({
                    type: failureType,
                    error: error.message || 'Something bad happened',
                    location,
                })
            )
    );
};

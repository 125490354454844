function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

export default function FormattedTable(html) {
    this.initialize = html => {

        this.tables = document
            .getElementsByTagName('table')

    };
    this.update = html => {
        if (this.tables && Array.isArray(this.tables)) {
            this.tables.forEach(elem => {
                let newWrapper = document.createElement('div')
                newWrapper.style.overflowX = "auto";
                wrap(elem, newWrapper);
            });
        }
    };
    // this.finalize = () => {
    // };
    this.initialize(html);
}



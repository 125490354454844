import './polyfills';
import 'lazysizes';
import '../../br-components/progressbar';
import '../../br-components/headermenu';

import 'buikit/src/assets/js/browserEnhancement';
import './icons';
import 'buikit/dist/buikit.ftl';
import '../scss/index.scss';
import reducers from './reducers';
import thunk from 'redux-thunk';
import htmlRequest from './middleware/htmlRequest';
import { applyMiddleware, createStore } from 'redux';
import Controller from './Controller';

const initalState = {};
if (window.beresa && window.beresa.account) {
    if (window.beresa.account.bookmarks) {
        initalState.bookmarks = window.beresa.account.bookmarks;
    }
    if (window.beresa.account.shoppingCartElements) {
        initalState.shoppingCartElements =
            window.beresa.account.shoppingCartElements;
    }
}
const store = createStore(
    reducers,
    initalState,
    applyMiddleware(thunk, htmlRequest)
);

new Controller({
    store,
});

import {addEvent} from "buikit/src/assets/js/utils/eventHandling";

export default function ChangeUserRoles({container, history}) {
    this.toFinalize = [];
    this.initialize = () => {
        this.eventListeners = [];

        let roleForms = container.querySelectorAll('.roles-change__form-role-selection');
        if (roleForms) {
            roleForms.forEach(form => {
                this.eventListeners.push(
                    addEvent(form, 'change', (e) =>
                        this.triggerDisableButtons(e)
                    )
                );
                this.disableDeleteButton(form);
            });
        }
    };
    this.triggerDisableButtons = (e) => {
        const form = e.target.form;
        this.disableDeleteButton(form);
    };
    this.disableDeleteButton = (form) => {
        const initialRoleId = form.querySelector('[name="roleId"]');
        const selectedRoleId = form.querySelector('option:checked');
        let deleteButton = form.querySelector('[value="delete"]');
        let addButton = form.querySelector('[value="add"]');
        if (initialRoleId && selectedRoleId && addButton && deleteButton) {
            if (initialRoleId.value !== "" && selectedRoleId.value !== "" && initialRoleId.value !== selectedRoleId.value) {
                deleteButton.style.display = "none";
                addButton.style.display = "block";
            } else if (selectedRoleId.value === "") {
                deleteButton.style.display = "none";
                addButton.style.display = "none";
            } else {
                deleteButton.style.display = "block";
                addButton.style.display = "block";
            }
        }
    };
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(event => event.remove());
        }
    };
    this.initialize();
}

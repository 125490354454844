import Bui from '../../assets/js/Bui';
import Equipmentslider from '../equipmentslider';
import { addEvent } from 'buikit/src/assets/js/utils/eventHandling';
import qs from 'query-string';

export default function Equipmentdetail({ container, config, store }) {
    this.initialize = () => {
        this.eventListeners = [];
        let hash = window.location.hash ? window.location.hash.substr(1) : '';
        this.qs = qs.parse(hash);
        this.equipmentsliderContainer = container.querySelector('.carslider');
        this.selectedVariantsInputField =
            container.querySelector('#selectedVariants');
        this.fromPriceTranslation = container.querySelector(
            '#fromPriceTranslationContainer'
        );
        this.selectionFields = container.querySelectorAll(
            '.equipmentdetail__variant_selection_field'
        );
        this.addToShoppingCartButton = container.querySelector(
            '.addToShoppingCartButton'
        );
        this.selectionFieldKeys = [];
        this.selectedVariants = [];
        this.variantPricingSpaces = container.querySelectorAll(
            '.equipmentdetail__pricing-spacer'
        );
        this.variantPrices = {};

        if (this.equipmentsliderContainer) {
            this.equipmentslider = new Equipmentslider(
                container.querySelector('.carslider'),
                {
                    nav: false,
                    threeSixty: false,
                    thumbItems: 5,
                    mouseDrag: false,
                }
            );
        }

        this.selectionFields.forEach((field) => {
            let select = field.querySelector('select');
            this.selectionFieldKeys.push(select.name);
            this.eventListeners.push(
                addEvent(select, 'change', (evt) =>
                    this.selectionChangeFunction(evt, select)
                )
            );
        });

        this.variantPricingSpaces.forEach((field) => {
            let variantId = field.dataset.pricesVariant;
            let netPrice = parseFloat(
                field.dataset.pricesNetPrice
                    ? field.dataset.pricesNetPrice.replace(',', '.')
                    : 0
            );
            let vatPrice = parseFloat(
                field.dataset.pricesVatPrice
                    ? field.dataset.pricesVatPrice.replace(',', '.')
                    : 0
            );
            this.variantPrices[variantId] = { net: netPrice, vat: vatPrice };
        });

        this.bui = new Bui({
            container,
        });

        this.filterVariantSelections();
        this.showLowestPrice();
        this.handleVariantSelectionDependentChanges();
    };

    this.selectionChangeFunction = (evt, select) => {
        let selectedIndex = evt.target.selectedIndex;
        let selectedOption = evt.target.options[selectedIndex];
        let optionVariants = selectedOption.dataset.variantSelectionVariants
            ? selectedOption.dataset.variantSelectionVariants.split(',')
            : [];
        if (
            this.selectedVariants.length > 0 &&
            this.selectionFieldKeys.indexOf(select.name) > 0
        ) {
            let newSelectedVariants = [];
            this.selectedVariants.forEach((v) => {
                if (optionVariants.includes(v)) {
                    newSelectedVariants.push(v);
                }
            });
            if (newSelectedVariants.length < 1) {
                newSelectedVariants = this.getSelectableVariantsTopToBottom(
                    this.selectionFieldKeys.indexOf(select.name)
                );
            }
            this.selectedVariants = newSelectedVariants;
        } else {
            this.selectedVariants = optionVariants;
        }
        const index = this.selectionFieldKeys.indexOf(select.name) + 1;
        this.filterVariantSelections(index);
        this.showLowestPrice();
        this.handleVariantSelectionDependentChanges();
    };

    this.handleVariantSelectionDependentChanges = () => {
        if (
            this.selectionFields.length < 1 ||
            this.selectedVariants.length === 1
        ) {
            this.fromPriceTranslation.style.visibility = 'hidden';
            this.addToShoppingCartButton.classList.remove('mod--disabled');
            this.addToShoppingCartButton.disabled = false;

            this.writeSelectedVariantIdToFavDom();
        } else {
            this.fromPriceTranslation.style.visibility = 'visible';
            this.addToShoppingCartButton.classList.add('mod--disabled');
            this.addToShoppingCartButton.disabled = true;

            this.disableStar();
        }
    };

    this.disableStar = () => {
        const bookmarkContainer = container.querySelector('[data-bookmark]');
        bookmarkContainer.setAttribute('data-bookmark-bookmarked', 'false');
    };

    this.writeSelectedVariantIdToFavDom = () => {
        const bookmarkContainer = container.querySelector('[data-bookmark]');
        if (this.selectedVariants.length === 1) {
            bookmarkContainer.setAttribute(
                'data-bookmark-sku',
                this.selectedVariants[0]
            );

            const { bookmarks } = store.getState() || {};
            bookmarkContainer.setAttribute(
                'data-bookmark-bookmarked',
                bookmarks.includes(this.selectedVariants[0]).toString()
            );
        } else {
            this.disableStar();
        }
    };

    this.getSelectableVariantsTopToBottom = (index) => {
        let newSelectedVariants = [];
        for (let i = 0; i <= index; i++) {
            let field = this.selectionFields[i];
            let select = field.querySelector('select');
            if (select.options.selectedIndex > 0) {
                let optionVariants =
                    select.options[select.options.selectedIndex].dataset
                        .variantSelectionVariants;
                if (optionVariants) {
                    if (newSelectedVariants.length > 0) {
                        let variants = [];
                        newSelectedVariants.forEach((v) => {
                            if (optionVariants.includes(v)) {
                                variants.push(v);
                            }
                        });
                        newSelectedVariants = variants;
                    } else {
                        newSelectedVariants = optionVariants.split(',');
                    }
                }
            }
        }
        return newSelectedVariants;
    };

    this.filterVariantSelections = (index) => {
        let fireEvent = false;
        let selectForFireEvent;
        for (let i = index ? index : 0; i < this.selectionFields.length; i++) {
            let field = this.selectionFields[i];
            let select = field.querySelector('select');
            if (
                this.selectedVariants != null &&
                this.selectedVariants.length > 0
            ) {
                select.options.forEach((option) => {
                    let optionVariants =
                        option.dataset.variantSelectionVariants;
                    if (optionVariants) {
                        let splittedOptionVariants = optionVariants.split(',');
                        if (
                            !this.selectedVariants.some((selected) =>
                                splittedOptionVariants.includes(selected)
                            )
                        ) {
                            option.style.display = 'none';
                            if (option.index === select.selectedIndex) {
                                select.selectedIndex = 0;
                            }
                        } else {
                            option.style.display = 'block';
                        }
                    }
                });
            }

            let firstSelect = this.selectionFields[0].querySelector('select');
            if (i > 0) {
                let field = this.selectionFields[i - 1];
                let parentSelect = field.querySelector('select');
                let parentSelectableOptions = Array.from(
                    parentSelect.options
                ).filter(filterSelectableOptions);
                let selectSelectableOptions = Array.from(select.options).filter(
                    filterSelectableOptions
                );
                if (
                    ((parentSelect.selectedIndex > 0 && index === i) ||
                        parentSelectableOptions.length < 2) &&
                    selectSelectableOptions.length > 1
                ) {
                    select.disabled = false;
                    if (index === i && selectSelectableOptions.length === 2) {
                        const indexOfOption =
                            getSelectableOptionIndexFromAllOptions(
                                selectSelectableOptions[1],
                                select.options
                            );
                        select.options[indexOfOption].selected = true;
                        select.selectedIndex = indexOfOption;
                        fireEvent = true;
                        selectForFireEvent = select;
                    } else {
                        select.selectedIndex = 0;
                    }
                } else {
                    select.disabled = true;
                    select.selectedIndex = 0;
                }
            } else if (firstSelect.options.length === 2) {
                firstSelect.options[1].selected = true;
                firstSelect.selectedIndex = 1;
                fireEvent = true;
                selectForFireEvent = firstSelect;
            }
        }
        if (this.selectedVariants && this.selectedVariants.length > 0) {
            this.selectedVariantsInputField.setAttribute(
                'value',
                this.selectedVariants
            );
        }
        if (fireEvent) {
            selectForFireEvent.dispatchEvent(new Event('change'));
        }
    };

    function getSelectableOptionIndexFromAllOptions(selectedOption, options) {
        for (let i = 0; i < options.length; i++) {
            if (options[i] === selectedOption) {
                return i;
            }
        }
        return -1;
    }

    function filterSelectableOptions(option) {
        return option.style.display !== 'none';
    }

    this.showLowestPrice = () => {
        let ids = Object.keys(this.variantPrices);
        if (this.selectedVariants != null && this.selectedVariants.length > 0) {
            ids = this.selectedVariants;
        }
        let variantId = this.getVariantIdWithLowestPrice(ids);
        this.variantPricingSpaces.forEach((field) => {
            let fieldId = field.dataset.pricesVariant;
            if (fieldId !== variantId) {
                field.style.display = 'none';
            } else {
                field.style.display = 'block';
            }
        });
    };

    this.getVariantIdWithLowestPrice = (ids) => {
        let variantId;
        let lowestPrice;
        ids.forEach((id) => {
            if (!lowestPrice || this.variantPrices[id].net < lowestPrice) {
                variantId = id;
                lowestPrice = this.variantPrices[id].net;
            }
        });
        return variantId;
    };

    this.finalize = () => {
        if (this.bui && typeof this.bui.finalize === 'function') {
            this.bui.finalize();
        }
        if (this.equipmentslider) {
            this.equipmentslider.finalize();
        }
        if (this.leasingCalculator) {
            this.leasingCalculator.finalize();
        }
        if (this.eventListeners.length) {
            this.eventListeners.forEach((l) => l.remove());
        }
        window.scroller.clear();
    };

    this.initialize();
}

import {addEvent} from 'buikit/src/assets/js/utils/eventHandling';
import {isVisible} from "../utils/AISearchVisibilityController";

export default function AISearchWidget({store}) {
    const self = this;
    const configUpdateListener = [];
    let aiSearchWidget;
    this.initialize = () => {
        this.eventListeners = [];
        this.eventListeners.push(addEvent(window, 'load', (e) => {
            this.initializeWidgetConfiguration();
        }));
        globalThis.aiSearchWidgetConfig.addListener(this.createWidgetAfterConfigLoaded);
        globalThis.aiSearchWidgetConfig.addListener(this.setFABVisibility);
    };

    this.onPageChange = ({html}) => {
        this.finalize();
        this.initializeTriggers();
        this.setFABVisibility();
    };

    this.createWidgetAfterConfigLoaded = (newConfig, oldConfig) => {
        const body = document.getElementsByTagName('body')[0];
        const widgetScript = document.createElement('script');
        widgetScript.id = 'aisearch__widget-script';
        widgetScript.src = newConfig.srcUrl;
        widgetScript.type = 'text/javascript';
        widgetScript.async = true;
        widgetScript.onload = function () {
            aiSearchWidget = window.aiSearchWidget;
            self.initializeTriggers();
            self.setFABVisibility();
            self.configureNavigator();
        }
        body.appendChild(widgetScript);

        const widgetRenderDiv = document.createElement('ai-search-widget');
        widgetRenderDiv.id = 'aisearch__widget-render-div';
        widgetRenderDiv.style.position = "absolute";
        widgetRenderDiv.style.zIndex = "1005";
        body.appendChild(widgetRenderDiv);
    }

    this.initializeWidgetConfiguration = () => {
        let stage = 'prod';
        const domainName = window.location.hostname.replace('www.', '');
        if (domainName === 'beresa.de') {
            stage = 'prod';
        } else if (domainName === 'demo.beresa.de') {
            stage = 'demo';
        } else if (domainName === 'staging.beresa.de') {
            stage = 'staging';
        } else if (domainName === 'localhost') {
            stage = 'staging';
        }

        fetch(`https://media.beresa.de/widget/config/${stage}/aisearch.json`, {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        })
            .then(response => response.json())
            .then(
                data => {
                    globalThis.aiSearchWidgetConfig.config = data;
                }
            )
            .catch(error => {
                console.log("error", error)
            });
    }

    this.initializeTriggers = () => {
        const widgetTrigger = window.document.querySelectorAll('.aisearch_widget-trigger');

        widgetTrigger.forEach(trigger => {
            this.eventListeners.push(addEvent(trigger, 'click', (e) => {
                aiSearchWidget.sidebar.toggle();
            }));
        });
    };

    this.setFABVisibility = () => {
        const fabButton = window.document.querySelector('#aisearch__widget-fab-button');
        if (fabButton) {
            const visibility = isVisible();
            if (visibility) {
                fabButton.style.display = 'flex';
            } else {
                fabButton.style.display = 'none';
            }
        }
    }

    this.configureNavigator = () => {
        aiSearchWidget.navigator.useDefaultNavigationBehavior(false);
        aiSearchWidget.navigator.onNavigate((path) => {
            globalThis.controllerHistory.push(path);
            aiSearchWidget.sidebar.toggle();
        });
    }

    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(listener => {
                listener.remove();
            });
        }
        if (self.bui) self.bui.finalize();
    };
    this.initialize();
}

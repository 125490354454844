import Bui from '../../assets/js/Bui';

export default function Contentsimple({ container, history }) {
    this.initialize = () => {
        this.bui = new Bui({
            container: container,
        });
    };

    this.finalize = () => {
        if (this.bui && typeof this.bui.finalize === 'function') {
            this.bui.finalize();
        }
    };
    this.initialize();
}

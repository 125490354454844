import Bui from '../../assets/js/Bui';
import { addEvent } from 'buikit/src/assets/js/utils/eventHandling';
import { joinQueryOptions } from 'buikit/src/assets/js/utils/urlHandling';
import {
    getFormData,
    FormValidator,
} from 'buikit/src/assets/js/utils/formHandling';
import fetchUrl from 'buikit/src/assets/js/utils/fetchUrl';

export default function Leasing({ container, history, config }) {
    const self = this;
    const parser = new DOMParser();
    this.initialize = () => {
        this.container = container;
        this.namespace = config.namespace;
        this.form = container.querySelector('.leasing__form');
        this.output = container.querySelector('.leasing__output');
        this.formEventListeners = [];
        this.initForm(this.form);
    };
    this.initOutputBui = container => {
        this.outputBui = new Bui({ container });
    };
    this.initForm = form => {
        this.submit = form.querySelector('.leasing__form-submit');
        this.customerTypeSwitches = Array.from(
            form.querySelectorAll('.leasing__ct-radio')
        );
        this.customerTypeRadios = Array.from(
            form.querySelectorAll('.leasing__ct-radio input')
        );

        this.bui = new Bui({ container: form });
        this.initOutputBui(this.output);

        this.fieldsToValidate = Array.from(
            this.form.querySelectorAll('[data-validate]')
        ).map(f => {
            try {
                const options = JSON.parse(f.getAttribute('data-validate'));
                options.name = f.name;
                return options;
            } catch {
                return null;
            }
        });
        this.formValidator = new FormValidator(
            this.form,
            this.fieldsToValidate,
            {
                controlForm: false,
                // customMessages: this.errorMessages
            }
        );
        this.fields = Array.from(
            this.form.querySelectorAll('select[name], input[name]')
        );
        this.fields.forEach(field => {
            this.formEventListeners.push(
                addEvent(field, 'change', function(e) {
                    self.form.dispatchEvent(
                        new Event('submit', {
                            cancelable: true,
                        })
                    );
                })
            );
        });
        this.formEventListeners.push(
            addEvent(this.form, 'submit', this.handleRequest)
        );
        this.customerTypeRadios.forEach(field => {
            this.formEventListeners.push(
                addEvent(field, 'change', this.handleCustomerTypeSwitch)
            );
        });

        this.handleCustomerTypeSwitch();
        if (form.hasAttribute('data-has-error')) {
            window.scroller.clear();
            const y =
                form.getBoundingClientRect().top + window.pageYOffset - 80;
            window.scroller.top(y);
        }
    };
    this.submitForm = () => {
        this.form.dispatchEvent(
            new Event('submit', {
                cancelable: true,
            })
        );
    };
    this.killForm = () => {
        if (this.bui) {
            this.bui.finalize();
        }
        if (this.outputBui) {
            this.outputBui.finalize();
        }
        if (this.formEventListeners.length) {
            this.formEventListeners.forEach(e => e.remove());
        }
    };
    this.handleCustomerTypeSwitch = () => {
        if (this.customerTypeSwitches && this.customerTypeSwitches.length) {
            this.customerTypeSwitches.forEach((zwitch, index) => {
                if (this.customerTypeRadios[index].checked) {
                    zwitch.classList.remove('mod--shy');
                } else {
                    zwitch.classList.add('mod--shy');
                }
            });
        }
    };

    this.handleRequest = e => {
        if (e) {
            e.preventDefault();
        }
        if (!this.formValidator.validate()) {
            return false;
        }
        let params = getFormData(this.form) || {};
        let currentNamespace = '';
        for (let param in params) {
            if (!currentNamespace.length) {
                currentNamespace = param.split(':')[0];
            }
            if (param.endsWith(':deposit_amount')) {
                params[param] = params[param].replace(',', '.');
                break;
            }
        }
        if (currentNamespace) {
            // if currentNamespace is set/has length, there must be fields and then send submit
            params[`${currentNamespace}:submit`] = true;
        }
        params = joinQueryOptions(params);

        fetchUrl({
            params,
            url: this.form.action,
            method: this.form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then(
            response => {
                const dom = parser.parseFromString(response.data, 'text/html');
                this.killForm();
                const newForm = dom.querySelector('.leasing__form');
                this.form.innerHTML = newForm.innerHTML;
                this.output.innerHTML = dom.querySelector(
                    '.leasing__output'
                ).innerHTML;
                if (newForm.hasAttribute('data-has-error')) {
                    this.form.setAttribute('data-has-error', '');
                } else {
                    this.form.removeAttribute('data-has-error');
                }
                this.initForm(this.form);
            },
            error => {
                // error message
                // console.log('###ERROR', error);
            }
        );
        return false;
    };
    this.finalize = () => {
        this.killForm();
        window.scroller.clear();
    };
    this.initialize();
}

import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";
import {addEvent} from "buikit/src/assets/js/utils/eventHandling";
import Bui from "../../assets/js/Bui";
import {getFormData} from "buikit/src/assets/js/utils/formHandling";

export default function B2B({container, history, config}) {
    this.toFinalize = [];
    const parser = new DOMParser();
    this.initialize = () => {
        this.container = container;
        this.bui = new Bui({container});
        this.eventListeners = [];
        this.initForm();
    };

    this.openModal = (e) => {
        // console.log("openModal");
        e.preventDefault();
        if (!e.target.disabled) {
            const copyOfInfotext = e.target.getAttribute("data-modal-description");
            const textWrapper = container.querySelector('.deletemodal__textwrapper');
            if (!!copyOfInfotext && !!textWrapper) {
                textWrapper.innerHTML = copyOfInfotext;
            }
            const b2bName = e.target.getAttribute("data-b2b-name");
            const nameWrapper = container.querySelector('.deletemodal__namewrapper');
            if (!!b2bName && !!nameWrapper) {
                nameWrapper.innerHTML = b2bName;
            }
            const b2bPackageUuid = e.target.getAttribute("data-b2b-package-uuid");
            const b2bVehicleUuid = e.target.getAttribute("data-b2b-vehicle-uuid");
            const okModalButton = container.querySelector('.deletemodal__ok');
            this.okModalButton.setAttribute("data-b2b-package-uuid", b2bPackageUuid);
            this.okModalButton.setAttribute("data-b2b-vehicle-uuid", b2bVehicleUuid);
            this.okModalButton.setAttribute("data-b2b-name", b2bName);
            this.modal.classList.add("visible");
        }
    };

    this.closeModal = () => {
        // console.log("closeModal");
        this.modal.classList.remove("visible");
    };

    this.initForm = () => {

        // console.log("initForm");
        this.form = this.container.querySelector("form");
        // console.log("form: ", this.form);

        if (this.form == null) {
            console.log("unable to find package_form");
        } else {
            let actionUrl = this.form.getAttribute("action");
            actionUrl = removeURLParameter(actionUrl, "action");
            actionUrl = removeURLParameter(actionUrl, "uuid");
            actionUrl = removeURLParameter(actionUrl, "value");
            actionUrl = removeURLParameter(actionUrl, "package_id");
            actionUrl = removeURLParameter(actionUrl, "price");
            actionUrl = removeURLParameter(actionUrl, "discount");
            actionUrl = removeURLParameter(actionUrl, "internal_id");

            this.form.action = actionUrl;
            //console.log("setting action: ", this.form);
        }

        this.formButtons = this.container.querySelectorAll(
            '.b2b__data-form-button'
        );
        if (this.formButtons) {
            //console.log("initForm: this.formButtons=" + JSON.stringify(this.formButtons));
            this.formButtons.forEach(element => {
                //console.log("initForm: element=" + JSON.stringify(element));
                this.eventListeners.push(
                    addEvent(element, 'click', (evt) => {
                        //console.log("initForm: pushing listener for click event");
                        evt.preventDefault();
                        this.handleButtonClick(evt);
                    })
                );
            });
        }
        // console.log("initForm: assigned event handlers to buttons");

        this.openModalDeletePackage = document.querySelectorAll('#deletePackageButton');
        this.openModalDeleteVehicle = document.querySelectorAll('#deleteVehicleButton');
        this.modal = this.container.querySelector('.deletemodal');
        this.closeModalIcon = document.querySelector('.deletemodal__close');
        this.okModalButton = document.querySelector('.deletemodal__ok');
        this.closeModalBackdrop = document.querySelector('.deletemodal__backdrop');

        if (this.openModalDeletePackage) {
            if (this.openModalDeletePackage.length > 0) {
                this.openModalDeletePackage.forEach(button => {
                    this.eventListeners.push(
                        addEvent(button, "click", this.openModal)
                    );
                });
            }
        }
        if (this.openModalDeleteVehicle) {
            if (this.openModalDeleteVehicle.length > 0) {
                this.openModalDeleteVehicle.forEach(button => {
                    this.eventListeners.push(
                        addEvent(button, "click", this.openModal)
                    );
                });
            }
        }
        if (this.okModalButton) {
            this.eventListeners.push(
                addEvent(this.okModalButton, "click", this.closeModal)
            );
        }
        if (this.closeModalIcon) {
            this.eventListeners.push(
                addEvent(this.closeModalIcon, "click", this.closeModal)
            );
        }
        if (this.closeModalBackdrop) {
            this.eventListeners.push(
                addEvent(this.closeModalBackdrop, "click", this.closeModal)
            );
        }

    }

    this.handleButtonClick = (evt) => {
        // console.log("handleButtonClick");
        // console.log("event: ", evt);
        // console.log("form: ", this.form);
        let params = getFormData(this.form);
        // console.log("params: ", params);
        params.action = evt.target.getAttribute("value");
        if (params.action === "edit_package"
                || params.action === "save_package"
                || params.action === "add_vehicle") {
            params.package_uuid = evt.target.getAttribute("data-b2b-package-uuid");
            params.vehicle_uuid = evt.target.getAttribute("data-b2b-vehicle-uuid");
            // console.log("action: " + params.action
            //    + ", package_uuid: " + params.package_uuid
            //    + ", vehicle_uuid: " + params.vehicle_uuid
            //    + ", internal_id: " + params.internal_id);
        }
        if (params.action === "delete_package"
                || params.action === "delete_vehicle") {
            params.package_uuid = evt.target.getAttribute("data-b2b-package-uuid");
            params.vehicle_uuid = evt.target.getAttribute("data-b2b-vehicle-uuid");
            params.internal_id = evt.target.getAttribute("data-b2b-name");
            // console.log("action: " + params.action
            //    + ", package_uuid: " + params.package_uuid
            //    + ", vehicle_uuid: " + params.vehicle_uuid
            //    + ", internal_id: " + params.internal_id);
        }
        this.performRestCall(params);
    }

    this.performRestCall = (params) => {
        // console.log("performRestCall");
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
        let actionUrl = this.form.getAttribute("action");
        actionUrl = removeURLParameter(actionUrl, "action");
        actionUrl = removeURLParameter(actionUrl, "package_uuid");
        actionUrl = removeURLParameter(actionUrl, "vehicle_uuid");
        actionUrl = removeURLParameter(actionUrl, "value");
        fetchUrl({
            params,
            url: actionUrl,
            method: this.form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    const dom = parser.parseFromString(response.data, 'text/html');
                    const newContainer = dom.querySelector('[data-component=\'{"name": "B2B"}\']');
                    this.container.innerHTML = newContainer.innerHTML;
                    this.bui = new Bui({container});
                    this.initForm();
                }
            );
    };
    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(event => event.remove());
        }
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
    };

    function removeURLParameter(url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    this.initialize();
}
import {addBookmark, deleteBookmark} from '../actions';
import {addLiveEvent} from 'buikit/src/assets/js/utils/eventHandling';
import config from '../config';
import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";

export default function Bookmarker({store}) {
    const self = this;
    this.init = () => {
        addLiveEvent(config.bookmarkSelector, 'click', this.handleClick);
        this.actions = {
            ADD: addBookmark,
            DELETE: deleteBookmark,
        };
        this.unsubscribeFromStore = store.subscribe(this.handleStoreChange);
    };
    this.toggle = (action = 'ADD', id) => {
        store.dispatch(this.actions[action](id));
    };
    this.handleStoreChange = function () {
        const {bookmarks} = store.getState() || {};
        const bookmarkElems = document.querySelectorAll(
            config.bookmarkSelector
        );
        bookmarkElems.forEach(b => {
            const id = b.getAttribute('data-bookmark');
            const sku = b.getAttribute('data-bookmark-sku');
            let marked = false;
            if (sku) {
                marked = bookmarks.includes(sku);
            } else {
                marked = bookmarks.includes(id);
            }
            b.setAttribute(
                'data-bookmark-bookmarked',
                marked.toString()
            );
        });
    };
    this.handleClick = function (e) {
        e.preventDefault();
        const form = this;
        const pending = form.getAttribute('data-bookmark-pending');
        if (pending) {
            // TODO Output message “Saving”
            return;
        }
        const {bookmarks} = store.getState() || {};
        const namespace = form.getAttribute('data-bookmark-namespace'),
            carId = form.getAttribute('data-bookmark'),
            sku = form.getAttribute('data-bookmark-sku');
        let action = form.getAttribute('data-bookmark-action');

        if (sku) {
            action = bookmarks.includes(sku) ? 'DELETE' : 'ADD';
        } else {
            action = bookmarks.includes(carId) ? 'DELETE' : 'ADD';
        }

        form.setAttribute('data-bookmark-pending', 'true');

        const params = {
            [`${namespace}:carId`]: carId,
            [`${namespace}:sku`]: sku,
            [`${namespace}:opt`]: action // TODO get action if in redux then remove else add
        };
        const reversedAction = action === 'DELETE' ? 'ADD' : 'DELETE';

        fetchUrl({
            params,
            url: form.action,
            method: form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    if (sku) {
                        self.toggle(action, sku);
                    } else {
                        self.toggle(action, carId);
                    }
                },
                error => {
                }
            )
            .finally(() => {
                form.removeAttribute('data-bookmark-pending');
            });
    };
    this.init();
}

import scrollMonitor from 'scrollmonitor';
import TimezZ from './timezz.js';

export default function Countdown({container, history, config}) {
    this.initialize = () => {
        this.target = container.querySelector('.countdown__target');
        //console.log(config);
        this.watcher = scrollMonitor.create(this.target, {
            top: -config.offset || 100,
        });

        this.template = container.querySelector('.template').innerHTML;

        this.watcher.enterViewport(() => {
            this.countdown = new TimezZ(this.target, {
                date: config.date,
                template: this.template,
                text: {
                    days: config.textDays,
                    hours: config.textHours,
                    minutes: config.textMinutes,
                    seconds: config.textSeconds,
                    day: config.textDay,
                    hour: config.textHour,
                    minute: config.textMinute,
                    second: config.textSecond,
                },
            });
        }, true);
    };
    this.finalize = () => {
        if (this.countdown) {
            this.countdown.destroy();
        }
        if (this.watcher) {
            this.watcher.destroy();
        }
    };
    this.initialize();
}

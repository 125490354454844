import {tns} from 'tiny-slider/src/tiny-slider';
import Carslider from '../carslider';

export default function Productstream({container, history}) {
    this.toFinalize = [];
    this.initialize = () => {
        const self = this;
        this.wrapper = container.querySelector('.swiper-wrapper');
        this.swiper = new tns({
            container: this.wrapper,
            items: 1,
            slideBy: 'page',
            mouseDrag: true,
            preventActionWhenRunning: true,
            controls: true,
            nextButton: container.querySelector('.swiper-button-next'),
            prevButton: container.querySelector('.swiper-button-prev'),
            nav: false,
            navPosition: 'bottom',
            loop: false,
            responsive: {
                768: {
                    items: 2,
                },
                1180: {
                    items: 3,
                },
            },
        });

        // Initialize all carsliders
        this.carsliders = [...container.querySelectorAll('.carslider')].map(
            slider => {
                const has360 = slider.querySelector(
                    '.carslider__thumbnail--360'
                );

                return new Carslider(slider, {
                    nav: true,
                    thumbItems: 3,
                    mouseDrag: false,
                    thumbAxis: 'horizontal',
                    threeSixty: has360 ? true : false,
                });
            }
        );

        // Auto adjustment breaks productstream in some cases cause of racecondition
        //this.adjustCardHeights();
    };
    this.adjustCardHeights = () => {
        this.cards = container.querySelectorAll('.swiper-slide');
        this.cards = this.cards || [];

        let highestCardsHeight = 0;

        const measureSize = (card) => {
            let currCardHeight = card.getBoundingClientRect().height;
            highestCardsHeight = (currCardHeight > highestCardsHeight) ? currCardHeight : highestCardsHeight;
        };

        this.cards.forEach(measureSize);

        this.cards.forEach((card) => {
            card.style.height = highestCardsHeight + "px";
        });
    };
    this.finalize = () => {
        if (this.swiper) {
            this.swiper.destroy();
        }

        if (this.carsliders) {
            this.carsliders.forEach(slider => slider.finalize());
        }
    };
    this.initialize();
}

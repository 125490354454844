import { addEvent } from 'buikit/src/assets/js/utils/eventHandling';
import Bui from '../../assets/js/Bui';
import Leasing from '../leasing/leasing-subcomponent';
import Carslider from '../carslider';
import Threesixtymodal from '../threesixtymodal';
import queryString from 'query-string';

export default function Productdetail({ container, config }) {
    const self = this;
    this.initialize = () => {
        this.eventListeners = [];
        let hash = window.location.hash ? window.location.hash.substr(1) : '';
        this.qs = queryString.parse(hash);
        this.carsliderContainer = container.querySelector('.carslider');
        this.threeSixtyImage = container.querySelector(
            '.carslider__image--360'
        );
        this.threeSixtyThumbnail = container.querySelector(
            '.carslider__thumbnail--360'
        );
        this.has360 = this.threeSixtyThumbnail ? true : false;
        if (this.carsliderContainer) {
            this.carslider = new Carslider(
                container.querySelector('.carslider'),
                {
                    nav: true,
                    threeSixty: this.has360 ? true : false,
                    thumbItems: 5,
                    mouseDrag: true,
                }
            );
        }
        if (this.threeSixtyImage) {
            this.threeSixtyImage.addEventListener('click', (e) =>
                this.openThreeSixtyModal()
            );
            this.threeSixtyThumbnail.addEventListener('click', (e) =>
                this.openThreeSixtyModal()
            );
        }
        if (this.qs['360'] === 'open') {
            this.openThreeSixtyModal();
        }
        let tabs = container.querySelector('.tabs');
        let isLeasing = false;
        // if leasing component is on the same page, go get it and shift it to the tab thing
        const sibling = container.nextElementSibling;
        if (sibling && tabs) {
            const leasing = sibling.querySelector('[data-subcomponent]');
            if (
                leasing &&
                /"name":(\s*)"Leasing"/i.test(
                    leasing.getAttribute('data-subcomponent')
                )
            ) {
                // we found a leasing subcomponent
                isLeasing = true;
                // now let us shift it to the tab
                const newTab = document.createElement('DIV');
                newTab.classList.add('tabs__tab');
                newTab.setAttribute('data-tabs-tab', '');

                let leasingMarkup = leasing.outerHTML;
                leasingMarkup = leasingMarkup.replace(
                    /(action=")(\S*)(")/,
                    '$1$2&offerno=' + config.offerno + '$3'
                );
                let leasingConfig = {};
                try {
                    leasingConfig = JSON.parse(
                        leasing.getAttribute('data-subcomponent')
                    );
                } finally {
                }
                leasing.remove();

                const svg = tabs
                    .querySelector('[data-tabs-title] svg')
                    .cloneNode(true);

                newTab.innerHTML = `
                    <h3 class="tabs__title data-leasing-tabs-title" data-tabs-title data-leasing-tabs-title>${config.leasingTabTitle}</h3>
                    <div class="tabs__flyout" data-tabs-flyout>
                        <div class="tabs__content" data-tabs-content>
                            <div>
                                ${leasingMarkup}
                            </div>
                        </div>
                    </div>
                `;
                newTab.querySelector('[data-tabs-title]').appendChild(svg);
                tabs.appendChild(newTab);
                setTimeout(() => {
                    this.leasingCalculator = new Leasing({
                        container: container.querySelector(
                            '.tabs [data-subcomponent]'
                        ),
                        config: leasingConfig,
                    });
                }, 100);
            }
        }
        this.bui = new Bui({
            container,
        });
        if (isLeasing) {
            tabs = container.querySelector('.tabs');
            if (tabs) {
                const goToLeasing = () => {
                    window.scroller.clear();
                    const y =
                        tabs.getBoundingClientRect().top +
                        window.pageYOffset -
                        80;
                    this.loadLeasing();

                    tabs.querySelector(
                        '[data-leasing-tabs-title]'
                    ).dispatchEvent(new Event('click', { cancelable: true }));
                    window.scroller.top(y);
                };

                let financingButton = container.querySelector(
                    '.productdetail__financing-button'
                );
                if (this.qs.leasing && this.qs.leasing === 'open') {
                    setTimeout(goToLeasing, 200);
                }
                if (financingButton) {
                    this.eventListeners.push(
                        addEvent(financingButton, 'click', (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            goToLeasing();
                        })
                    );
                }
                this.eventListeners.push(
                    addEvent(tabs, 'click', (e) => {
                        this.loadLeasing();
                    })
                );
            }
        }
    };
    this.loadLeasing = () => {
        if (!this.leasingHasBeenLazyLoaded) {
            this.leasingHasBeenLazyLoaded = true;
            this.leasingCalculator.submitForm();
        }
    };

    this.openThreeSixtyModal = () => {
        let modalElement = container.querySelector('.threesixtymodal');
        if (modalElement) {
            this.threesixtymodal = new Threesixtymodal(modalElement);
        }
    };

    this.finalize = () => {
        if (this.bui && typeof this.bui.finalize === 'function') {
            this.bui.finalize();
        }
        if (this.carslider) {
            this.carslider.finalize();
        }
        if (this.leasingCalculator) {
            this.leasingCalculator.finalize();
        }
        if (this.eventListeners.length) {
            this.eventListeners.forEach((l) => l.remove());
        }
        window.scroller.clear();
    };

    this.initialize();
}

import appConfig from '../../assets/js/config';
import fetchUrl from 'buikit/src/assets/js/utils/fetchUrl';
import {addEvent, addLiveEvent} from "buikit/src/assets/js/utils/eventHandling";
import debounce from "lodash/debounce";

const {restBaseUrl} = appConfig;

const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;
const arrowKeys = [UP_ARROW_KEY_CODE, DOWN_ARROW_KEY_CODE];
const KEYWORD_BANNER_ID = "keywordBannerSearchResult";
const PRODUCTS_ID = "productSearchResult";
const CONTACT_PERSONS_ID = "contactPersonResult";
const LOCATION_ID = "locationSearchResult";
const JOBS_ID = "jobsSearchResult";
const NEWS_ID = "newsSearchResult";
const OFFER_ID = "offerSearchResult";
const CONTENT_DOCUMENTS_ID = "contentSearchResult";
const CONTAINERS = [KEYWORD_BANNER_ID, PRODUCTS_ID, JOBS_ID, NEWS_ID, OFFER_ID, CONTENT_DOCUMENTS_ID, CONTACT_PERSONS_ID, LOCATION_ID];
const ITEM_LINK_SELECTOR_CLASS = "global-search-bar__itemLink";
const DOC_TYPES_TO_CONTAINER_ID = {
    "starterstoreboot:Standort": LOCATION_ID,
    "starterstoreboot:Contactperson": CONTACT_PERSONS_ID,
    "starterstoreboot:Job": JOBS_ID,
    "starterstoreboot:NewsHubItem": NEWS_ID,
    "starterstoreboot:SalesHubItem": OFFER_ID,
    "starterstoreboot:KeywordBanner": KEYWORD_BANNER_ID,
    "starterstoreboot:contentdocument": CONTENT_DOCUMENTS_ID
}

export default function GlobalSearchBar({container, history, config}) {
    const self = this;
    let lastTerm = '';
    this.initialize = () => {
        this.events = [];
        this.baseURL = window.location.origin;
        if (window.location.pathname.startsWith(("/site/"))) {
            this.baseURL += "/site/"
        }
        if (!this.baseURL.endsWith("/")) {
            this.baseURL += "/";
        }
        this.restURL = this.baseURL + restBaseUrl;

        this.setState = newState => {
            const oldState = this.state;
            this.state = {
                ...this.state,
                ...newState,
            };
            this.onStateChange(oldState, this.state);
            return this.state;
        };

        this.input = container.querySelector('.input__input');
        this.searchResultContainer = container.querySelector('#searchResultContainer');
        this.searchBarButton = container.querySelector('.search-bar__button');
        this.valueListContainer = container.querySelector('.global-search-bar__value-list-container');
        if (this.valueListContainer) {
            this.fueltypeContainer = this.valueListContainer.querySelector('#fueltype');
        }

        this.containers = this.getContainers();
        this.dataConfigs = {
            maxCount: 0,
            containers: {},
            order: []
        };
        this.getDataConfigs();

        if (this.input) {
            this.events = [
                addEvent(this.input, 'keydown', debounce(this.handleKey, 1000)),
                // escape key closes suggestions
                addEvent(
                    document,
                    'keydown',
                    e => e.keyCode === ESCAPE_KEY_CODE && this.closeSuggestions()
                ),
                addLiveEvent('a', 'keydown', this.handleItemKeys, this.result),
                addEvent(document, 'click', this.closeSuggestions),
                addEvent(container, 'submit', this.handleSubmit),
            ];

            if (this.searchBarButton) {
                this.events.push(addEvent(this.searchBarButton, 'click', e => this.navigateToSearchPage(this.input.value ? this.input.value : "")))
            }
        }

        let searchTerm = getUrlParameter('term');
        if (searchTerm && searchTerm.length > 0 && this.dataConfigs.isOnSearchPage) {
            this.request(searchTerm);
        }
    };
    this.getContainers = () => {
        let result = {};
        CONTAINERS.forEach(id => {
            let con = container.querySelector('#' + id);
            if (con) {
                result[id] = container.querySelector('#' + id);
            }
        });
        return result;
    };
    this.getDataConfigs = () => {
        if (this.searchResultContainer) {
            this.dataConfigs = {
                ...this.dataConfigs,
                maxCount: parseInt(this.searchResultContainer.getAttribute("data-max-count")),
                isOnSearchPage: this.searchResultContainer.getAttribute("data-is-on-search-page") === 'true'
            }
        }
        for (let prop in this.containers) {
            if (this.containers.hasOwnProperty(prop)) {
                this.dataConfigs.containers[prop] = {
                    ...this.containers[prop],
                    minCount: parseInt(this.containers[prop].getAttribute("data-min-count")),
                    priority: parseInt(this.containers[prop].getAttribute("data-priority"))
                }
            }
        }
        this.sortByPriority();
    };
    this.sortByPriority = () => {
        let result = [];
        for (let prop in this.dataConfigs.containers) {
            if (this.dataConfigs.containers.hasOwnProperty(prop)) {
                result.push({id: prop, priority: this.dataConfigs.containers[prop].priority})
            }
        }
        this.dataConfigs.order = result.sort((a, b) => (a.priority > b.priority) ? 1 : -1);
    };
    this.onStateChange = prevState => {
        let result = {};
        this.dataConfigs.order.forEach(order => {
            result[order.id] = this.getMinNumberItems(order.id);
            this.renderResult(this.containers[order.id], result[order.id]);
        });
        let size = this.getResultSize(result);
        if (size < this.dataConfigs.maxCount) {
            this.dataConfigs.order.forEach(order => {
                result[order.id] = [...result[order.id], ...this.fillUpToMax(order.id, result)];
                this.renderResult(this.containers[order.id], result[order.id]);
            });
        }
    };

    function flattenItems(data) {
        let flattenItems = [];
        if (data) {
            data.forEach(item => {
                if (item.link && item.link.length > 0) {
                    item.link.forEach(link => {
                        flattenItems.push({
                            ...item,
                            link: link
                        })
                    });
                } else if (item["starterstoreboot:target"] && item["starterstoreboot:target"].length > 0) {
                    item["starterstoreboot:target"].forEach(link => {
                        flattenItems.push({
                            ...item,
                            "starterstoreboot:target": link
                        })
                    });
                }
            });
            return flattenItems;
        } else {
            return [];
        }
    }

    this.fillUpToMax = (id, result) => {
        if (this.state[id]) {
            let totalResultSize = this.getResultSize(result);
            let lostToTotalMax = this.dataConfigs.maxCount - totalResultSize;
            let containerResultSize = result[id].length;
            let containerAvailableSize = this.state[id].length;
            if (totalResultSize < this.dataConfigs.maxCount) {
                if (containerResultSize < containerAvailableSize) {
                    if (containerAvailableSize - containerResultSize <= lostToTotalMax) {
                        return this.state[id].slice(containerResultSize);
                    } else if (containerAvailableSize >= containerResultSize + lostToTotalMax) {
                        return this.state[id].slice(containerResultSize, containerResultSize + lostToTotalMax);
                    }
                }
            }
        }
        return [];
    };
    this.getMinNumberItems = (id) => {
        if (this.state[id]) {
            return this.state[id].slice(0, this.dataConfigs.containers[id].minCount);
        } else {
            return [];
        }
    };
    this.getResultSize = (result) => {
        let size = 0;
        for (let prop in result) {
            if (result.hasOwnProperty(prop)) {
                size += result[prop].length;
            }
        }
        return size;
    };
    this.request = (term) => {
        this.fetchProducts(term);
        this.fetchAllDocuments(term);
        // this.fetchKeywordBanner(term);
        // this.fetchContactPersons(term);
        // this.fetchLocationDocuments(term);
        // this.fetchContentDocuments(term);
        // this.fetchJobs(term);
        // this.fetchNews(term);
        // this.fetchOffers(term);
        this.replacePlaceholderWithSearchTerm(term);
    };
    this.replacePlaceholderWithSearchTerm = (term) => {
        for (let prop in this.containers) {
            if (this.containers.hasOwnProperty(prop)) {
                let linkText = this.containers[prop].querySelector('.global-search-bar__search-term-placeholder');
                if (linkText) {
                    linkText.innerHTML = term;
                }
                let linkContainer = this.containers[prop].querySelector('.global-search-bar__search-term-placeholder-container');
                if (linkContainer) {
                    let hrefValue = linkContainer.getAttribute("data-href");
                    if (hrefValue) {
                        linkContainer.setAttribute("href", hrefValue.replace("{{searchParam}}", term));
                        linkContainer.setAttribute("search-param", hrefValue.replace("{{searchParam}}", term));
                    }
                }
            }
        }

        let searchPageTitleTextContainer = container.querySelector('#global-search-bar__search-page-title-text');
        if (searchPageTitleTextContainer) {
            searchPageTitleTextContainer.innerHTML = searchPageTitleTextContainer.innerHTML.replace("{{searchParam}}", term);
            searchPageTitleTextContainer.style.display = "block";
        }
    };
    this.fetchAllDocuments = (term) => {
        this.fetchContentApi(Object.keys(DOC_TYPES_TO_CONTAINER_ID).join(","), undefined, term);
    };
    this.fetchLocationDocuments = (term) => {
        this.fetchContentApi("starterstoreboot:Standort", LOCATION_ID, term);
    };
    this.fetchContentDocuments = (term) => {
        this.fetchContentApi(undefined, CONTENT_DOCUMENTS_ID, term, "contentSearchResult");
    };
    this.fetchContactPersons = (term) => {
        this.fetchContentApi("starterstoreboot:Contactperson", CONTACT_PERSONS_ID, term);
    };
    this.fetchJobs = (term) => {
        this.fetchContentApi("starterstoreboot:Job", JOBS_ID, term);
    };
    this.fetchNews = (term) => {
        this.fetchContentApi("starterstoreboot:NewsHubItem", NEWS_ID, term);
    };
    this.fetchOffers = (term) => {
        this.fetchContentApi("starterstoreboot:SalesHubItem", OFFER_ID, term);
    };
    this.fetchKeywordBanner = (term) => {
        this.fetchContentApi("starterstoreboot:KeywordBanner", KEYWORD_BANNER_ID, term, undefined, "starterstoreboot:priority", "descending", 1);
    };
    this.fetchContentApi = (docType, stateId, term, excludeId, orderBy, sorting, limit) => {
        let url = this.restURL + "content?_offset=0";
        if (docType && docType !== "") {
            url += "&_nodetype=" + docType;
        }
        if (term && term !== "") {
            //url += "&_query=" + encodeURI("*" + term + "*");
            url += "&_query=" + encodeURI(term);
        }
        if (excludeId && excludeId !== "") {
            url += "&_excludeId=" + excludeId;
        }
        if (orderBy && orderBy !== "") {
            url += "&_orderBy=" + orderBy;
        }
        if (sorting && sorting !== "") {
            url += "&_sortOrder=" + sorting;
        }
        if (limit && limit !== "") {
            url += "&_max=" + limit;
        } else {
            url += "&_max=" + this.dataConfigs.maxCount;
        }
        fetchUrl({
            url: url,
            method: "GET",
            headers: {
                'content-type': 'application/json',
            },
        })
            .then(
                response => {
                    if (stateId) {
                        if (stateId === KEYWORD_BANNER_ID) {
                            this.setState({[stateId]: response.data.items});
                        } else {
                            this.setState({[stateId]: flattenItems(response.data.items)});
                        }
                    } else {
                        this.interpretSearchResults(response.data.items);
                    }
                }
            );
    };
    this.interpretSearchResults = (items) => {
        let groupedResult = {};
        items.forEach(item => {
            if (groupedResult[DOC_TYPES_TO_CONTAINER_ID[item.nodeType]] === undefined) {
                groupedResult[DOC_TYPES_TO_CONTAINER_ID[item.nodeType]] = [];
            }
            if (groupedResult[CONTENT_DOCUMENTS_ID] === undefined) {
                groupedResult[CONTENT_DOCUMENTS_ID] = [];
            }
            if (DOC_TYPES_TO_CONTAINER_ID[item.nodeType]) {
                groupedResult[DOC_TYPES_TO_CONTAINER_ID[item.nodeType]] = [...groupedResult[DOC_TYPES_TO_CONTAINER_ID[item.nodeType]], item];
            } else {
                groupedResult[CONTENT_DOCUMENTS_ID] = [...groupedResult[CONTENT_DOCUMENTS_ID], item];
            }
        });
        let resultState = {};
        Object.values(DOC_TYPES_TO_CONTAINER_ID).forEach(containerId => {
            if (containerId !== KEYWORD_BANNER_ID) {
                resultState[containerId] = flattenItems(groupedResult[containerId]);
            } else {
                resultState[containerId] = groupedResult[containerId];
            }
        });

        this.setState(resultState);
    }
    this.fetchProducts = (term) => {
        let url = this.restURL + "products";
        if (term && term !== "") {
            url += "?fullText=" + term;
        }
        fetchUrl({
            url: url,
            method: "GET",
            headers: {
                'content-type': 'application/json',
            },
        })
            .then(
                response => {
                    this.setState({[PRODUCTS_ID]: [...response.data]});
                }
            );
    };

    function getPreviewStartIndex(contentLength, indexOfSearchTerm) {
        const x = 0;
        if (indexOfSearchTerm - x > 0) {
            return indexOfSearchTerm - x;
        } else {
            return 0;
        }
    }

    function getPreviewEndIndex(contentLength, indexOfSearchTerm) {
        const x = 135;
        if (indexOfSearchTerm + x > contentLength) {
            return contentLength;
        } else {
            return indexOfSearchTerm + x;
        }
    }

    this.renderResult = (template, items) => {
        this.searchResultContainer.style.display = "block";
        if (items.length < 1) {
            template.style.display = "none";
        } else {
            let preProcessedTemplate = template.querySelector('.global-search-bar__template').cloneNode(true);
            const imagePlaceholderLink = template.getAttribute('data-placeholder-image');
            let carLink = preProcessedTemplate.getAttribute("data-car-url");
            let shopLink = preProcessedTemplate.getAttribute("data-equipment-url");
            if (template.getAttribute("id") === PRODUCTS_ID) {
                let shopLinkContainer = container.querySelector('.global-search-bar__product-shop-link');
                let onlyEquipmentFound = true;
                for (let i = 0; i < items.length; i++) {
                    if (items[i]["product_type"] === "used_car") {
                        onlyEquipmentFound = false;
                        break;
                    }
                }
                let linkValue = carLink;
                if (onlyEquipmentFound) {
                    linkValue = shopLink;
                }
                let allProductShopLinks = shopLinkContainer.querySelectorAll('a');
                allProductShopLinks.forEach(link => {
                    let searchParam = link.getAttribute("search-param");
                    if (searchParam) {
                        link.setAttribute("href", linkValue + searchParam);
                    } else {
                        link.setAttribute("href", linkValue);
                    }
                });
            }
            template.style.display = "block";

            let renderedItems = [];
            items.forEach(item => {
                let tempNode = preProcessedTemplate.cloneNode(true);
                tempNode.querySelector('a').classList.add(ITEM_LINK_SELECTOR_CLASS);
                let slug = item["slug"];
                if (carLink) {
                    slug = carLink + "/" + item["slug"];
                }
                if (item["product_type"] !== "used_car") {
                    let carData = tempNode.querySelector('.global-search-bar__car-data');
                    if (carData) {
                        carData.style.display = "none";
                    }
                    if (shopLink) {
                        slug = shopLink + "/" + item["slug"];
                    }
                }
                let mileage = item["mileage"];
                if (mileage) {
                    mileage = parseFloat(mileage).toLocaleString('de')
                }
                let fuelType = item["fuelType"];
                if (fuelType && this.fueltypeContainer) {
                    let fuelTypeElement = this.fueltypeContainer.querySelector('[data-key="' + fuelType + '"]');
                    if (fuelTypeElement) {
                        fuelType = fuelTypeElement.getAttribute("data-value");
                    }
                }
                let publicationDate = "";
                if (item["hippostdpubwf:publicationDate"]) {
                    publicationDate = new Date(item["hippostdpubwf:publicationDate"]);
                    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
                    publicationDate = publicationDate.toLocaleDateString(undefined, options);
                }
                let targetUrl = "";
                if (item["starterstoreboot:target"] && item["starterstoreboot:target"].url) {
                    targetUrl = item["starterstoreboot:target"].url;
                }
                let contactPersonUrl = "";
                if (item["starterstoreboot:firstname"] && item["starterstoreboot:firstname"].length > 0
                    && item["starterstoreboot:lastname"] && item["starterstoreboot:lastname"].length > 0) {
                    contactPersonUrl = item["starterstoreboot:firstname"] + "+" + item["starterstoreboot:lastname"];
                }
                let keywordLink = "";
                if (item["starterstoreboot:internalLink"] && item["starterstoreboot:internalLink"].length > 0) {
                    keywordLink = item["starterstoreboot:internalLink"][0].url;
                } else if (item["starterstoreboot:externalLink"]) {
                    keywordLink = item["starterstoreboot:externalLink"];
                }
                let contentPreview = "";
                if (item["hippostd:content"]) {
                    const span = document.createElement('span');
                    span.innerHTML = item["hippostd:content"];
                    const content = span.innerText;

                    let indexOfSearchTerm = content.toLowerCase().indexOf(lastTerm.toLowerCase());
                    if (indexOfSearchTerm !== -1) {
                        let previewStartIndex = getPreviewStartIndex(content.length, indexOfSearchTerm);
                        let previewEndIndex = getPreviewEndIndex(content.length, indexOfSearchTerm);
                        let preview = content.substring(previewStartIndex, previewEndIndex);
                        let leadingDots = previewStartIndex > 0 ? "... " : "";
                        let endingDots = previewEndIndex < content.length ? " ... " : "";
                        contentPreview = leadingDots + preview + endingDots;
                    } else {
                        let previewEndIndex = getPreviewEndIndex(content.length, 0);
                        contentPreview = content.substring(0, previewEndIndex);
                    }
                }

                let productImageUrl;
                if (item["imageLinks"] && item["imageLinks"].length > 0) {
                    productImageUrl = item["imageLinks"][0]["small"];
                    tempNode.querySelector('.global-search-bar__image-src-placeholder').src = productImageUrl;
                } else if (item["product_type"] === "used_car") {
                    const carPlaceholderImage = tempNode.getAttribute("data-small-car-placeholder-image");
                    if (carPlaceholderImage) {
                        tempNode.querySelector('.global-search-bar__image-src-placeholder').src = carPlaceholderImage;
                    }
                } else if (imagePlaceholderLink) {
                    tempNode.querySelector('.global-search-bar__image-src-placeholder').src = imagePlaceholderLink;
                }

                if (item["starterstoreboot:small"]) {
                    tempNode.querySelector('.global-search-bar__image-src-placeholder').src = item["starterstoreboot:small"].url;
                }
                if (item["starterstoreboot:avatarUrl"]) {
                    tempNode.querySelector('.global-search-bar__image-src-placeholder').src = item["starterstoreboot:avatarUrl"];
                }
                let location = "&nbsp;";
                const locationValue = item["starterstoreboot:location"];
                if (locationValue && locationValue.length > 0 && locationValue[0] !== "") {
                    location = "in " + item["starterstoreboot:location"].join(", ");
                }

                let netPrice = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(item["price"]);

                let temp = tempNode.innerHTML;

                if (item["price"] === item["grossPrice"]) {
                    temp = temp.replace('data-decide="do-not-render-if-net-equals-gross"', 'style="display: none"');
                } else {
                    temp = temp.replace(/{{product_netprice}}/g, netPrice.replace("€", "").trim())
                }

                let location_url = "";
                if (item["starterstoreboot:siteurl"]) {
                    location_url = item["starterstoreboot:siteurl"];
                    if (location_url.startsWith("/")) {
                        location_url = location_url.substring(1);
                    }
                    location_url = this.baseURL + location_url;
                }
                renderedItems.push(temp
                    .replace(/{{url}}/g, item.link ? item.link.url : "")
                    .replace(/{{location_url}}/g, location_url)
                    .replace(/{{target_url}}/g, targetUrl)
                    .replace(/{{contact_person_target_url}}/g, contactPersonUrl)
                    .replace(/{{title}}/g, item["starterstoreboot:title"] ? item["starterstoreboot:title"] : "")
                    .replace(/{{product_title}}/g, item.title)
                    .replace(/{{slug}}/g, slug)
                    .replace(/{{content_preview}}/g, contentPreview)
                    //.replace(/{{product_image}}/g, productImageUrl)
                    //.replace(/{{image}}/g, item["starterstoreboot:small"] ? item["starterstoreboot:small"].url : "")
                    .replace(/{{product_price}}/g, new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR'
                    }).format(item["grossPrice"]))
                    .replace(/{{vatrate}}/g, item["vatRate"])
                    .replace(/{{first_registration_date}}/g, item["firstRegistrationDate"])
                    .replace(/{{mileage}}/g, mileage)
                    .replace(/{{fuel_type}}/g, fuelType)
                    .replace(/{{starterstore_title}}/g, item["starterstoreboot:title"])
                    .replace(/{{department}}/g, item["starterstoreboot:department"])
                    .replace(/{{phonenumber}}/g, item["starterstoreboot:phone"] ? item["starterstoreboot:phone"] : "")
                    .replace(/{{publication_date}}/g, publicationDate)
                    .replace(/{{fullname}}/g, item["starterstoreboot:firstname"] + " " + item["starterstoreboot:lastname"])
                    .replace(/{{location_name}}/g, item["starterstoreboot:sitename"])
                    .replace(/{{address_street}}/g, item["starterstoreboot:street"])
                    .replace(/{{address_no}}/g, item["starterstoreboot:no"])
                    .replace(/{{address_plz}}/g, item["starterstoreboot:zipcode"])
                    .replace(/{{address_location}}/g, item["starterstoreboot:sitename"])
                    .replace(/{{jobs_title}}/g, item["starterstoreboot:name"])
                    .replace(/{{jobs_locations}}/g, location)
                    .replace(/{{content}}/g, item["hippostd:content"] ? item["hippostd:content"] : "")
                    .replace(/{{keyword_link}}/g, keywordLink)
                )
            });
            template.querySelector('.result').innerHTML = (renderedItems.join(""));
        }
    };
    this.closeSuggestions = () => {
        lastTerm = '';
        this.searchResultContainer.style.display = "none";
        for (let prop in this.containers) {
            if (this.containers.hasOwnProperty(prop)) {
                this.containers[prop].querySelector('.result').innerHTML = '';
            }
        }
        let newState = {...this.state};
        CONTAINERS.forEach(id => {
            if (newState[id]) {
                newState[id] = [];
            }
        });
        this.setState(newState);
    };
    this.handleItemKeys = e => {
        if (e.keyCode === ESCAPE_KEY_CODE) {
            this.input.focus();
            return;
        }
        if (!arrowKeys.includes(e.keyCode)) {
            return;
        }
        // get current items
        const suggestionLinks = Array.from(
            this.searchResultContainer.querySelectorAll('.' + ITEM_LINK_SELECTOR_CLASS) || []
        );
        if (!suggestionLinks.length) {
            return;
        }
        const index = suggestionLinks.indexOf(e.target);
        if (e.keyCode === UP_ARROW_KEY_CODE && index === 0) {
            // go back to the input
            this.input.focus();
        } else {
            const newIndex = Math.min(
                suggestionLinks.length - 1,
                e.keyCode === UP_ARROW_KEY_CODE ? index - 1 : index + 1
            );
            suggestionLinks[newIndex].focus();
        }
        e.preventDefault();
    };
    this.handleKey = e => {
        const term = e.target.value.trim();

        if (e.keyCode !== ESCAPE_KEY_CODE && term.length > 2) {
            if (e.keyCode === DOWN_ARROW_KEY_CODE) {
                // focus suggestion result list

                // get current items
                const suggestionLinks = Array.from(
                    this.searchResultContainer.querySelectorAll('.' + ITEM_LINK_SELECTOR_CLASS) || []
                );
                if (suggestionLinks.length) {
                    suggestionLinks[0].focus();
                }
            } else if (e.keyCode === ENTER_KEY_CODE) {
                this.navigateToSearchPage(term);
            } else if (term !== lastTerm) {
                // request if term is a new one
                this.request(term);
            }
        } else {
            this.closeSuggestions();
        }
        lastTerm = term;
    };
    this.navigateToSearchPage = (term) => {
        let url = this.baseURL;
        url += "suche";
        if (term && term !== "") {
            url += "?term=" + term;
        }
        window.location.href = url;
    };

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    this.finalize = () => {
        if (this.events.length) {
            this.events.forEach(event => event.remove());
        }
    };
    this.initialize();
}

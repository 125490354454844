import scrollMonitor from 'scrollmonitor';
import {CountUp} from 'countup.js';

export default function Countup({container, history, config}) {
    this.initialize = () => {
        //console.log(config);
        this.target = container.querySelector('.countup__target');

        this.watcher = scrollMonitor.create(this.target, {
            top: -config.offset || 100,
        });

        this.counter = new CountUp(this.target, config.endVal, {
            startVal: config.startVal || 0,
            duration: config.duration || 3,
            separator: '.',
        });

        this.watcher.enterViewport(() => {
            this.counter.start();
        }, true);
    };
    this.finalize = () => {
        if (this.counter) {
            this.counter = null;
        }
        if (this.watcher) {
            this.watcher.destroy();
        }
    };
    this.initialize();
}

export default function Stylevalue({ container }) {
    this.toFinalize = [];
    this.initialize = () => {
        this.body = document.body;
        this.removeStyleClasses();

        this.styleValueDiv = container.querySelector(
            '.style-component_style-value'
        );
        if (this.styleValueDiv) {
            const styleValue = this.styleValueDiv.dataset.styleValue;
            if (styleValue) {
                this.body.classList.add(styleValue);
            }
        }
    };

    this.removeStyleClasses = () => {
        const values = [...this.body.classList];
        values.forEach(value => {
            if (value.startsWith('color-scheme-')) {
                this.body.classList.remove(value);
            }
        });
        if (this.body.classList.length === 0) {
            this.body.removeAttribute('class');
        }
    };

    this.finalize = () => {
        this.removeStyleClasses();
    };
    this.initialize();
}

import Bui from '../../assets/js/Bui';
import {addEvent} from "buikit/src/assets/js/utils/eventHandling";
import fetchUrl from "buikit/src/assets/js/utils/fetchUrl";
import {getFormData} from "buikit/src/assets/js/utils/formHandling";

export default function ServiceBooking({container, history}) {
    const self = this;
    const parser = new DOMParser();
    this.initialize = () => {
        this.eventListeners = [];

        this.container = container;
        this.bui = new Bui({container});
        this.initForm();
    };

    this.finalize = () => {
        if (self.bui) self.bui.finalize();
    };

    this.initForm = () => {
        this.form = container.querySelector("form");

        this.selects = this.form.querySelectorAll(
            "select"
        );

        if (this.selects) {
            this.selects.forEach(element => {
                this.eventListeners.push(
                    addEvent(element, 'change', (evt) => {
                        evt.preventDefault();
                        this.performRestCall(evt)
                    })
                );
            });
        }
    }

    this.performRestCall = (evt) => {
        if (this.bui) this.bui.finalize();
        if (this.tween) this.tween.kill();
        let actionUrl = this.form.getAttribute("action");
        actionUrl = removeURLParameter(actionUrl, "selectedServiceBookingCategoryKey");
        actionUrl = removeURLParameter(actionUrl, "selectedServiceBookingLocationDealerId");
        let params = getFormData(this.container.querySelector("form")) || {};
        fetchUrl({
            params,
            url: actionUrl,
            method: "POST",
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(
                response => {
                    const dom = parser.parseFromString(response.data, 'text/html');
                    const newContainer = dom.querySelector('[data-component=\'{"name": "ServiceBooking"}\']');
                    this.container.innerHTML = newContainer.innerHTML;
                    this.bui = new Bui({container});
                    this.initForm();
                }
            );
    };

    function removeURLParameter(url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    this.initialize();
}

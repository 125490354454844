import {addEvent} from 'buikit/src/assets/js/utils/eventHandling';

export default function AddToCardModal({container, history, config}) {
    const self = this;

    this.initialize = () => {
        this.eventListeners = [];
        this.container = container;

        this.namespace = config.namespace;

        this.openTriggerButton = document.querySelectorAll('.addToShoppingCartButton');
        this.closeTriggerIcon = document.querySelector('.addtocardmodal__close');
        this.closeTriggerButton = document.querySelector('.addtocardbutton_close_modal');
        this.closeTriggerBackdrop = document.querySelector('.addtocardmodal__backdrop');

        if (this.openTriggerButton) {
            if (this.openTriggerButton.length > 0) {
                this.openTriggerButton.forEach(button => {
                    this.eventListeners.push(
                        addEvent(button, "click", this.openModal)
                    );
                });
            }
        }
        if (this.closeTriggerButton) {
            this.eventListeners.push(
                addEvent(this.closeTriggerButton, "click", this.closeModal)
            );
        }
        if (this.closeTriggerIcon) {
            this.eventListeners.push(
                addEvent(this.closeTriggerIcon, "click", this.closeModal)
            );
        }
        if (this.closeTriggerBackdrop) {
            this.eventListeners.push(
                addEvent(this.closeTriggerBackdrop, "click", this.closeModal)
            );
        }
    };

    this.openModal = (e) => {
        if (!e.target.disabled) {
            const copyOfInfotext = e.target.getAttribute("data-modal-description");
            const mode = e.target.getAttribute("data-modal-mode");
            
            const textWrapper = container.querySelector('.addtocardmodal__textwrapper');
            const imageWrapper = container.querySelector('.addtocardmodal__imagewrapper');

            if (mode === "package") {
                const packageCarTitle = document.querySelectorAll('.productdetail__package-car-title');
                let html = "<ul>";
                packageCarTitle.forEach(car => {
                    html += "<li>" + car.getAttribute('data-car-title') + "</li>";
                });
                html += "</ul>"
                textWrapper.innerHTML = html;
                textWrapper.style = 'max-width:100%;padding-right:2rem';

                if (!!imageWrapper) {
                    imageWrapper.src = "";
                }
            } else {
                textWrapper.style = undefined;
                if (!!copyOfInfotext && !!textWrapper) {
                    textWrapper.innerHTML = copyOfInfotext;
                }

                const copyOfImage = e.target.getAttribute("data-modal-picture");

                if (!!copyOfImage && copyOfImage && !!imageWrapper) {
                    imageWrapper.src = copyOfImage;
                }
            }

            this.container.classList.add("visible");
        }
    };

    this.closeModal = () => {
        this.container.classList.remove("visible");
    };

    this.finalize = () => {
        if (this.eventListeners.length) {
            this.eventListeners.forEach(listener => {
                listener.remove();
            });
        }
        if (self.bui) self.bui.finalize();
        if (self.tween) self.tween.kill();
    };
    this.initialize();
}

import {
    addShoppingCartItem,
    deleteShoppingCartItem,
    setShoppingCartItem,
} from '../actions';
import { addLiveEvent } from 'buikit/src/assets/js/utils/eventHandling';
import config from '../config';
import fetchUrl from 'buikit/src/assets/js/utils/fetchUrl';
import qs from 'query-string';

export default function ShoppingCartElement({ store }) {
    const self = this;
    this.init = () => {
        addLiveEvent(config.shoppingCartSelector, 'click', this.handleClick);
        this.actions = {
            ADD: addShoppingCartItem,
            DELETE: deleteShoppingCartItem,
            SET: setShoppingCartItem,
        };
    };
    this.addToScore = (action = 'ADD', id) => {
        const { shoppingCartElements } = store.getState();
        const oldCount = shoppingCartElements[id];
        if (oldCount) {
            // do nothing cause element is already on cart
        } else {
            store.dispatch(this.actions[action](id, 1));
        }
    };
    this.handleClick = function (e) {
        e.preventDefault();

        if (!e.target.disabled) {
            const form = this;
            const action = 'ADD';
            const variantInputField = form.querySelector('#selectedVariants');
            const pidField = form.querySelector('#_pid');
            const addButton = form.querySelector('.addToShoppingCartButton');
            const packageId = form.querySelector('#packageId');
            const successButton = form.querySelector(
                '.addToShoppingCartButtonSuccess'
            );
            if (variantInputField) {
                self.addToScore(
                    action,
                    variantInputField.getAttribute('value')
                );
            }

            if (packageId) {
                const packageCarSkus = form.querySelectorAll(
                    '.productdetail__package-car-sku'
                );
                packageCarSkus.forEach((car) => {
                    self.addToScore(action, car.getAttribute('data-car-sku'));
                });
            }

            let formData = {
                _pid: pidField ? pidField.getAttribute('value') : undefined,
                selectedVariants: variantInputField
                    ? variantInputField.getAttribute('value')
                    : undefined,
                packageId: packageId
                    ? packageId.getAttribute('value')
                    : undefined,
            };

            addButton.style.display = 'none';
            successButton.style.display = 'block';
            setTimeout(() => {
                addButton.style.display = 'block';
                successButton.style.display = 'none';
            }, 2000);

            fetchUrl({
                url: form.getAttribute('data-action'),
                method: form.getAttribute('data-method'),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: qs.stringify(formData),
            }).then(
                (response) => {
                    // console.log('Product added to cart.');
                },
                (error) => {
                    // we assumed a successful XHR, but it failed — we have to undo the change of the frontend store
                    //self.toggle(reversedAction, carId);
                    // console.log('error');
                }
            );
        }
    };
    this.init();
}

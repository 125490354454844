import * as ActionTypes from '../actions';
import paginate from './pagination';
import { combineReducers } from 'redux';

const location = (state = {}, action) => {
    if (action.type === ActionTypes.HTML_SUCCESS) {
        return {
            ...state,
            ...action.location,
        };
    }
    return state;
};

const html = (state = {}, action) => {
    if (action.type === ActionTypes.HTML_SUCCESS) {
        const rawHtml = action.response.data;
        const body = document.createElement('div');
        const newRegexp = /<body.*?>((.|[\n\r])*)<\/body>/gim;
        let rawBody = newRegexp.exec(rawHtml);
        if (rawBody) {
            rawBody = rawBody[1];
        }
        body.innerHTML = rawBody;
        const content = body.querySelector('#content');

        return {
            ...state,
            rawHtml,
            rawBody,
            body,
            content,
        };
    }
    return state;
};

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
    const { type, error } = action;

    if (type === ActionTypes.RESET_ERROR_MESSAGE) {
        return null;
    } else if (error) {
        return error;
    }

    return state;
};

// Updates the pagination data for different actions.
const pagination = combineReducers({
    html: paginate({
        mapActionToKey: (action) => action.key,
        types: [
            ActionTypes.HTML_REQUEST,
            ActionTypes.HTML_SUCCESS,
            ActionTypes.HTML_FAILURE,
        ],
    }),
});

const bookmarks = (state = [], action) => {
    if (action.type == ActionTypes.ADD_BOOKMARK) {
        return [...state, action.id];
    } else if (action.type == ActionTypes.DELETE_BOOKMARK) {
        const index = state.indexOf(action.id);
        if (index > -1) {
            const curState = [...state];
            curState.splice(index, 1);
            return curState;
        }
        return [...state, action.id];
    } else if (action.type == ActionTypes.CLEAR_BOOKMARK) {
        // console.log('Clear bookmarks');
        return [];
    }
    return state;
};

const shoppingCartElements = (state = {}, action) => {
    const itemId = action.id;
    const count = action.count;
    if (action.type === ActionTypes.ADD_SHOPPING_CART_ITEM) {
        return { ...state, [itemId]: count };
    } else if (action.type === ActionTypes.DELETE_SHOPPING_CART_ITEM) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
    } else if (action.type === ActionTypes.SET_SHOPPING_CART_ITEMS) {
        return { ...action };
    } else if (action.type === ActionTypes.CLEAR_SHOPPING_CART_ITEMS) {
        return {};
    }
    return state;
};

const reducers = combineReducers({
    location,
    bookmarks,
    shoppingCartElements,
    html,
    pagination,
    errorMessage,
});

export default reducers;
